import React, {useState, useEffect } from 'react'
import {
    Box, 
    Button, 
    Grid, 
    Card, 
    CardContent, 
    CardActionArea, 
    Typography, 
    TextField, 
    FormControl, 
    InputLabel, 
    MenuItem, 
    FormControlLabel,
    RadioGroup,
    Radio,
    FormLabel,
    FormHelperText 
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Select from '@mui/material/Select';

import HomeIcon from '@mui/icons-material/Home';
import { useHistory  } from 'react-router-dom';
import MyBreadcrumb from './../../components/MyBreadcrumb';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import config from '../../core/config';
import MySnackbar from '../../components/MySnakebar';



const StudentCreateAndEdit = () => {
    let history = useHistory();

    const [boardsAndClasses, setBoardsAndClasses] = useState({
        classes: [],
        boards: [],
    });

    const [ showSnakebar, setShowSnakebar ] = useState(false);
    const [ snakebardata, setSnakebardata ] = useState({
        label : '',
        color: "inherit",
    });

    const [ formErrors, setFormErrors ] = useState({});
    const [ isSubmit, setIsSubmit ] = useState(false);


    const [formData, setFormData] = useState({
        name : '',
        // email : '',
        phone : '',
        // dob : new Date(""),
        gender : '',
        iclass : '',
        board : '',
    });

    const breadcrumbs = [
        {label: "Dashboard", uri: "dashboard", icon: <HomeIcon fontSize="small" /> },
        {label: "Students", uri: "student", icon: null},
        {label: "Add New", uri: null, icon: null}
    ];


    const fetchBoardsAndClassesList = async () => {
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.get(config.rootUrl + "/getClassAndBoard").then(({ data }) => {
          if (data.status) {
            setBoardsAndClasses(data.data);
          }
        }).catch(err => {
          console.log(err);
        });
    }

    useEffect(() => {
        fetchBoardsAndClassesList();
    }, []);


    const handelChange = (e, val = null, fieldName = null) => {
        // if(fieldName != null && fieldName == "dob" && val != null) {
        //     setFormData({...formData, [fieldName] : val});
        // } else {
            const {name, value} = e.target;
            setFormData({...formData, [name] : value});
        // }
    }

    

    const formValidate = (values) => {
        const errors = {};
        if(!values.name) {
            errors.name = "Student Name is required!";
        }
        // if(!values.email) {
        //     errors.email = "Email Adderess is required!";
        // }
        if(!values.phone) {
            errors.phone = "Phone is required!";
        }
        if(!values.gender) {
            errors.gender = "Gender is required!";
        }
        // if(!values.dob) {
        //     errors.dob = "Date of Birth is required!";
        // }
        if(!values.board) {
            errors.board = "Board is required!";
        }
        if(!values.iclass) {
            errors.iclass = "Class is required!";
        }
        return errors;
    }

    const handelSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(formValidate(formData));
        
        if(Object.keys(formErrors).length === 0) {
            console.log("Data", formData);
            var token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            await axios.post(config.rootUrl + "/student/create", formData)
            .then(({ data }) => {
                console.log(data);
                if (data.status) {
                    
                    setShowSnakebar(true);
                    setSnakebardata({
                        label: data.message,
                        color: "success",
                        type: "success"
                    })
                    setTimeout(()=>{
                        history.push('student');
                    }, 2000)
                } else {
                    setShowSnakebar(true);
                    setSnakebardata({
                        label: data.message,
                        color: "error",
                        type: "error"
                    })
                }
            }).catch(err => {
              console.log(err);
            });
        }
        setIsSubmit(true);
    }

    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formData);
        }
        console.log(formErrors);
    }, [formErrors])

    return (
        <div className="main">

            <MyBreadcrumb data={breadcrumbs} />
            <br />
            <Typography align="left" color="secondary" gutterBottom variant="h6" component="div">
                Add Student
            </Typography>
            <Box sx={{flexGrow: 1, marginY: 2}}>
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        <form onSubmit={handelSubmit}>
                            <div className="form-box">
                                <FormControl fullWidth>
                                    <TextField id="outlined-basic"
                                        name="name"
                                        label="Student Name" variant="outlined" 
                                        onChange={handelChange}
                                        error={(formErrors.name && true)}
                                        helperText={ formErrors.name }
                                    />
                                </FormControl>
                            </div>
                            {/* <div className="form-box">
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            label="Date of Birth"
                                            name='dob'
                                            inputFormat="MM/dd/yyyy"
                                            value={formData.dob}
                                            onChange={ (val) => {
                                                handelChange(null, val, "dob")
                                            }}
                                            renderInput={(params) => <TextField {...params} 
                                            error={(formErrors.dob && true)}
                                            helperText={formErrors.dob} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div> */}
                            <div className="form-box x-pad">
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
                                    <RadioGroup

                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handelChange}
                                    >
                                        <FormControlLabel value="f" control={<Radio />} label="Female" />
                                        <FormControlLabel value="m" control={<Radio />} label="Male" />
                                    </RadioGroup>
                                    <FormHelperText error={(formErrors.gender && true)}>{formErrors.gender}</FormHelperText>
                                </FormControl>
                            </div>
                            <div className="form-box">
                                <FormControl fullWidth>
                                    <TextField id="outlined-basic" label="Email Address" variant="outlined"
                                        name="email"
                                        onChange={handelChange}
                                        error={(formErrors.email && true)}
                                        helperText={formErrors.email}
                                    />
                                </FormControl>
                            </div>
                            <div className="form-box">
                                <FormControl fullWidth>
                                    <TextField id="outlined-basic" label="Phone or Mobile" variant="outlined"
                                        name="phone"
                                        onChange={handelChange}
                                        error={(formErrors.phone && true)}
                                        helperText={formErrors.phone}
                                    />
                                </FormControl>
                            </div>
                            <div className="form-box">
                                <FormControl fullWidth error={(formErrors.board && true)}>
                                    <InputLabel id="demo-board-select-autowidth-label">Board</InputLabel>
                                    <Select
                                        labelId="demo-board-select-autowidth-label"
                                        id="demo-board-select-autowidth"
                                        value={formData.board}
                                        label="Board"
                                        name="board"
                                        onChange={handelChange}
                                    >
                                        {
                                            boardsAndClasses.boards.map((item, i) => {
                                                return <MenuItem key={i} value={item.id}>{item.name }</MenuItem>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText error={(formErrors.board && true)}>{formErrors.board}</FormHelperText>
                                </FormControl>
                            </div>
                            <div className="form-box">
                                <FormControl fullWidth error={(formErrors.iclass && true)}>
                                    <InputLabel id="demo-class-select-autowidth-label">Class</InputLabel>
                                    <Select
                                        labelId="demo-class-select-autowidth-label"
                                        id="demo-class-select-autowidth"
                                        value={formData.iclass}
                                        label="Class"
                                        name="iclass"
                                        onChange={handelChange}
                                    >
                                        {
                                            boardsAndClasses.classes.map((item, i) => {
                                                return <MenuItem key={i} value={item.id}>{item.name }</MenuItem>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText error={(formErrors.iclass && true)}>{formErrors.iclass}</FormHelperText>
                                </FormControl>
                            </div>
                            <div className="form-box">
                                <FormControl fullWidth>
                                    <Button type="submit" color="primary" variant="contained" size="large">Add</Button>
                                </FormControl>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </Box>
            {
                showSnakebar ? <MySnackbar data={snakebardata} visible={showSnakebar} handleClose={()=> {setShowSnakebar(false)}} /> : ""
            }
        </div>
    )
}

export default StudentCreateAndEdit;