import React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import { useHistory  } from 'react-router-dom';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
});

const MyBreadcrumb = ({data = [], ...rest}) => {

    let history = useHistory();

    return (
        <div role="presentation">
            <Breadcrumbs color="primary.light" aria-label="breadcrumb">
                {
                    data.map((item, i) => {
                        return <StyledBreadcrumb
                            key={i}
                            component="a"
                            color='secondary'
                            href="#"
                            label={item.label}
                            icon={item.icon}
                            onClick={()=> {
                                if(item.uri !== null) {
                                    history.push(item.uri)
                                }
                            }}
                        />
                    })
                }

            </Breadcrumbs>
        </div>
    );
}

export default MyBreadcrumb;
