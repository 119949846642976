import axios from "axios";
import Swal from "sweetalert2";
import config from "../../core/config"
import { setWallet } from "./WalletActions";

export const set_login = (data) => {
    return (dispatch) => {
        dispatch({
            type: "SET_LOGIN",
            payload: data
        });
        dispatch(setWallet('user', data.wallet))
    };
}

export const make_logout = () => {
    return (dispatch) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'User will have Admin Privileges',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then(res => {
            if (res.isConfirmed) {
                var token = localStorage.getItem('token');
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                axios.post(`${config.rootUrl}/logout`, {
                    token: token
                }).then((res) => {
                    dispatch({
                        type: "MAKE_LOGOUT"
                    });
                    localStorage.clear();

                }).catch((err) => {
                    console.log(err);
                });
            }
        });

    }
}