import React, { useState, useEffect } from "react";
import MyLoader from "./../components/MyLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Grid,
  Divider,
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import MySnackbar from "./../components/MySnakebar";
import axios from "axios";
import config from "./../core/config";
import { setWallet } from "../store/actions/WalletActions";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Profile = () => {
  const [progress, setProgress] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showSnakebar, setShowSnakebar] = useState(false);
  const [snakebardata, setSnakebardata] = useState({
    label: "",
    color: "inherit",
    type: "success",
  });

  const userState = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [user, setUser] = useState(userState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name ?? "",
    address: user.address_line_2 ?? "",
    pincode: user.pincode ?? "",
    account_holder: user.account_holder ?? "",
    bank_name: user.bank_name ?? "",
    account_number: user.account_number ?? "",
    ifsc_code: user.ifsc_code ?? "",
    nominee: user.nominee ?? "",
    nominee_relation: user.nominee_relation ?? "",
    addhar_no: user.addhar_no,
    // id_proof_file : '',
    pan_no: user.pan_no ?? null,
  });

  const formValidate = (values) => {
    const errors = {};
    errors.name = !values.name ? "Name is required" : "";
    errors.address = !values.address ? "Address is required" : "";
    errors.pincode = !values.address ? "Pincode is required" : "";
    // errors.account_holder = !values.account_holder ? "Account Holder is required" : "";
    // errors.bank_name = !values.bank_name ? "Bank Name is required" : "";
    // errors.account_number = !values.account_number ? "Account No. is required" : "";
    // errors.ifsc_code = !values.ifsc_code ? "IFSC Code is required" : "";
    // errors.nominee = !values.nominee ? "Nominee is required" : "";
    // errors.nominee_relation = !values.nominee_relation ? "Relation with nominee is required" : "";
    // errors.addhar_no = !values.addhar_no ? "Aadhar No. is required" : "";
    // errors.id_proof_file = !values.id_proof_file ? "ID Proof File is required" : "";
    return errors;
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    console.log("Data", formData);

    setFormErrors(formValidate(formData));
    console.log("Errors", formErrors);

    // if(Object.keys(formErrors).length === 0) {
    var token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // {headers: {'Content-Type': 'multipart/form-data'}}
    await axios
      .post(config.rootUrl + "/user/update", formData)
      .then(({ data }) => {
        //   console.log(data);
        if (data.status) {
          setShowSnakebar(true);
          setSnakebardata({
            label: data.message,
            color: "success",
            type: "success",
          });
          fetchRealtimeUserData();
          setEditMode(false);
        } else {
          setShowSnakebar(true);
          setSnakebardata({
            label: data.message,
            color: "error",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
    setIsSubmit(true);
  };

  const fetchRealtimeUserData = () => {
    setProgress(true);
    var token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get(`${config.rootUrl}/user`)
      .then((res) => {
        setUser(res.data.data);
        dispatch({ type: "SET_LOGIN", payload: res.data.data });
        dispatch(setWallet("user"));
        setProgress(false);
      })
      .catch((err) => {
        console.log(err);
        setProgress(false);
      });
  };

  useEffect(() => {
    fetchRealtimeUserData();
  }, []);

  if (progress) {
    return (
      <MyLoader visible={progress} onclose={() => setProgress(!progress)} />
    );
  }

  return (
    <div style={{ padding: "10px", marginTop: "60px" }}>
      {editMode && (
        <Button
          type="button"
          variant="text"
          size="small"
          onClick={() => {
            setEditMode(false);
          }}
          color="primary"
        >
          <ChevronLeftIcon /> Go Back
        </Button>
      )}
      <div style={{ background: "#ffffff" }}>
        {editMode ? (
          <>
            <div className="main" style={{ marginTop: "10px" }}>
              <Typography
                align="left"
                color="primary"
                gutterBottom
                variant="h6"
                component="div"
              >
                Update Profile
              </Typography>
              <Box sx={{ flexGrow: 1, marginY: 2 }}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <form onSubmit={handelSubmit}>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="name"
                            label="Name"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                name: event.target.value,
                              });
                            }}
                            error={formErrors.name && true}
                            helperText={formErrors.name}
                            value={formData.name ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="address"
                            label="Address"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                address: event.target.value,
                              });
                            }}
                            error={formErrors.address && true}
                            helperText={formErrors.address}
                            value={formData.address ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="pincode"
                            label="Pincode"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                pincode: event.target.value,
                              });
                            }}
                            error={formErrors.pincode && true}
                            helperText={formErrors.pincode}
                            value={formData.pincode ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="account_holder"
                            label="Account Holder"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                account_holder: event.target.value,
                              });
                            }}
                            error={formErrors.account_holder && true}
                            helperText={formErrors.account_holder}
                            value={formData.account_holder ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="bank_name"
                            label="Bank Name"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                bank_name: event.target.value,
                              });
                            }}
                            error={formErrors.bank_name && true}
                            helperText={formErrors.bank_name}
                            value={formData.bank_name ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="account_number"
                            label="Account Number"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                account_number: event.target.value,
                              });
                            }}
                            error={formErrors.account_number && true}
                            helperText={formErrors.account_number}
                            value={formData.account_number ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="ifsc_code"
                            label="IFSC Code"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                ifsc_code: event.target.value,
                              });
                            }}
                            error={formErrors.ifsc_code && true}
                            helperText={formErrors.ifsc_code}
                            value={formData.ifsc_code ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="nominee"
                            label="Nominee Name"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                nominee: event.target.value,
                              });
                            }}
                            error={formErrors.nominee && true}
                            helperText={formErrors.nominee}
                            value={formData.nominee ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="nominee_relation"
                            label="Relation with Nominee"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                nominee_relation: event.target.value,
                              });
                            }}
                            error={formErrors.nominee_relation && true}
                            helperText={formErrors.nominee_relation}
                            value={formData.nominee_relation ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="addhar_no"
                            inputProps={{ maxLength: 12 }}
                            label="Aadhar No"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                addhar_no: event.target.value,
                              });
                            }}
                            error={formErrors.addhar_no && true}
                            helperText={formErrors.addhar_no}
                            value={formData.addhar_no ?? ""}
                          />
                        </FormControl>
                      </div>
                      <div className="form-box">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            name="pan_no"
                            inputProps={{ maxLength: 10 }}
                            label="Pancard No"
                            variant="outlined"
                            onChange={(event) => {
                              setFormData({
                                ...formData,
                                pan_no: event.target.value,
                              });
                            }}
                            value={formData.pan_no ?? ""}
                          />
                        </FormControl>
                      </div>
                      {/* <div className="form-box">
                                                    <FormControl fullWidth>
                                                        <Button variant="contained" color={(formErrors.id_proof_file) ? `error` : `primary`} 
                                                            component="label"
                                                            endIcon={<FileUploadIcon />}
                                                        >
                                                            Upload ID Proof File
                                                            <input hidden name="id_proof_file" accept="image/*" type="file" 
                                                                onChange={(event) => {setFormData({...formData, id_proof_file: event.target.files[0]})}}
                                                            />
                                                        </Button>
                                                        <FormHelperText error={(formErrors.id_proof_file && true)}>{formErrors.id_proof_file}</FormHelperText>
                                                    </FormControl>
                                                </div> */}
                      <div className="form-box">
                        <FormControl fullWidth>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                          >
                            Update Profile
                          </Button>
                        </FormControl>
                      </div>
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <UpdatePassword />
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} mb={2}>
                <List dense={true}>
                  <ListItem
                    secondaryAction={
                      <Button
                        variant="text"
                        edge="end"
                        aria-label="edit"
                        onClick={() => {
                          setEditMode(true);
                        }}
                      >
                        Edit&nbsp;{" "}
                        <EditIcon color="primary" sx={{ fontSize: 15 }} />
                      </Button>
                    }
                  >
                    <React.Fragment>
                      <ListItemAvatar>
                        <Avatar alt="Cindy Baker" src={user.image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="h6"
                              color="primary"
                            >
                              {user.name}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle2"
                              color="primary"
                            >
                              Referral Code : {user.user_unique_id}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </React.Fragment>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="subtitle2"
                          color="primary"
                        >
                          Phone
                        </Typography>
                      }
                      secondary={user.phone}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="subtitle2"
                          color="primary"
                        >
                          Email
                        </Typography>
                      }
                      secondary={user.email ?? "NA"}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="subtitle2"
                          color="primary"
                        >
                          Address
                        </Typography>
                      }
                      secondary={user.address_line_2 ?? "NA"}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="subtitle2"
                          color="primary"
                        >
                          Pincode
                        </Typography>
                      }
                      secondary={user.pincode ?? "NA"}
                    />
                  </ListItem>
                  <Divider />
                  {user.bank_detail !== null ? (
                    <>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle2"
                              color="primary"
                            >
                              Bank Account Number
                            </Typography>
                          }
                          secondary={user.bank_detail.account_number ?? "NA"}
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle2"
                              color="primary"
                            >
                              Bank Name
                            </Typography>
                          }
                          secondary={user.bank_detail.bank_name ?? "NA"}
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle2"
                              color="primary"
                            >
                              IFSC Code
                            </Typography>
                          }
                          secondary={user.bank_detail.ifsc_code ?? "NA"}
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle2"
                              color="primary"
                            >
                              Nominee Name
                            </Typography>
                          }
                          secondary={user.bank_detail.nominee ?? "NA"}
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle2"
                              color="primary"
                            >
                              Nominee Relation
                            </Typography>
                          }
                          secondary={user.bank_detail.nominee_relation ?? "NA"}
                        />
                      </ListItem>
                      <Divider />
                    </>
                  ) : (
                    <></>
                  )}
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="subtitle2"
                          color="primary"
                        >
                          Addhar Card No.
                        </Typography>
                      }
                      secondary={user.addhar_no ?? "NA"}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="subtitle2"
                          color="primary"
                        >
                          PAN Card No.
                        </Typography>
                      }
                      secondary={user.pan_no ?? "NA"}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </>
        )}
        {showSnakebar ? (
          <MySnackbar
            data={snakebardata}
            visible={showSnakebar}
            handleClose={() => {
              setShowSnakebar(false);
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const UpdatePassword = () => {
  const [progress, setProgress] = useState(false);
  const [showSnakebar, setShowSnakebar] = useState(false);
  const [snakebardata, setSnakebardata] = useState({
    label: "",
    color: "inherit",
    type: "success",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const formValidate = (values) => {
    const errors = {};
    errors.old_password = !values.old_password
      ? "Old Password is required"
      : "";
    errors.new_password = !values.new_password
      ? "New Password is required"
      : "";

    if(!values.confirm_password) {
        errors.confirm_password = "Confirm Password is required"
    } else if (values.confirm_password != values.new_password) {
        errors.confirm_password = "Confirm Password not match"
    } else {
        errors.confirm_password = ""
    }
    return errors;
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    console.log("Data", formData);

    setFormErrors(formValidate(formData));
    console.log("Errors", formErrors);

    // if(Object.keys(formErrors).length === 0) {
    var token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // {headers: {'Content-Type': 'multipart/form-data'}}
    await axios
      .post(
        `https://portal.vidyarthiindia.in/api/v1/vibox/auth/user/update-password`,
        formData
      )
      .then(({ data }) => {
        //   console.log(data);
        if (data.status) {
         setShowSnakebar(true);
          setSnakebardata({
            label: data.message,
            color: "success",
            type: "success",
          });
        } else {
          let htmlText;

          if ("errors" in data) {
            htmlText = data.errors.map((error, e) => {
              return `${error}\n`;
            });
          } else {
            htmlText = data.message;
          }
          setShowSnakebar(true);
          setSnakebardata({
            label: htmlText,
            color: "error",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
    setIsSubmit(true);
  };

  return (
    <>
    <div className="main" style={{ marginTop: "10px" }}>
      <Typography
        align="left"
        color="primary"
        gutterBottom
        variant="h6"
        component="div"
      >
        Update Profile
      </Typography>
      <Box sx={{ flexGrow: 1, marginY: 2 }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <form onSubmit={handelSubmit}>
              <div className="form-box">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    name="old_password"
                    label="Old Password"
                    variant="outlined"
                    onChange={(event) => {
                      setFormData({ ...formData, old_password: event.target.value });
                    }}
                    error={formErrors.old_password && true}
                    helperText={formErrors.old_password}
                  />
                </FormControl>
              </div>
              <div className="form-box">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    name="new_password"
                    label="New Password"
                    variant="outlined"
                    onChange={(event) => {
                      setFormData({ ...formData, new_password: event.target.value });
                    }}
                    error={formErrors.new_password && true}
                    helperText={formErrors.new_password}
                  />
                </FormControl>
              </div>
              <div className="form-box">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    name="confirm_password"
                    label="Confirm Password"
                    variant="outlined"
                    onChange={(event) => {
                      setFormData({ ...formData, confirm_password: event.target.value });
                    }}
                    error={formErrors.confirm_password && true}
                    helperText={formErrors.confirm_password}
                  />
                </FormControl>
              </div>
              <div className="form-box">
                <FormControl fullWidth>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Update Password
                  </Button>
                </FormControl>
              </div>
            </form>
          </Grid>
        </Grid>
      </Box>
    </div>
        {showSnakebar ? (
          <MySnackbar
            data={snakebardata}
            visible={showSnakebar}
            handleClose={() => {
              setShowSnakebar(false);
            }}
          />
        ) : (
          ""
        )}
    </>
    
  );
};

export default Profile;
