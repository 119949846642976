import React, { useState, useEffect } from 'react';

import {
    Typography,
    Fab,
    List,
    Grid,
    Drawer,
    Tabs,
    Tab,
    Box,
    AppBar,
    Input,
    InputAdornment
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import config from '../../core/config';
import MyLoader from '../../components/MyLoader';
import DownlevelDetails from './DownlevelDetails';
import DownlevelList from './DownlevelList';
import { useSelector } from 'react-redux';



// import PropTypes from 'prop-types';
// import SvgIcon from '@mui/material/SvgIcon';
// import { alpha, styled } from '@mui/material/styles';
// import TreeView from '@mui/lab/TreeView';
// import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
// import Collapse from '@mui/material/Collapse';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const DownlevelHome = (props) => {

    let history = useHistory();
    const user = useSelector((state) => { return state.auth.user })
    const [downlevelUserList, setDownlevelUserList] = useState({});
    const [downlevelUser, setDownlevelUser] = useState([]);
    const [levelList, setLevelList] = useState([]);
    const [levelId, setLevelId] = useState(parseInt(user.dkd_commission_struct_id)+1);
    const [progress, setProgress] = useState(false);

    const [downlevelIds, setDownlevelIds] = useState([]);
    
    const [open, setOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(null);
    const [selectedUser, setSelectedUser] = useState([]);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchData, setSearchData] = useState([]);

    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: 16,
    };


    const toggleDrawer = (event) => {
        setOpen(!open);
    };

    const handleTabChange = (event, newValue) => {
        console.log("VVV", event)
        setTabIndex(newValue);
        // fetchStudentsList(newValue);
        setSearchKeyword("")
    };

    // const fetchStudentsList = async (newValue = 0) => {
    //     setProgress(true);
    //     var token = localStorage.getItem('token');
    //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    //     await axios.post(config.rootUrl + "/user/downlevelUsers", {
    //         child_level_id: levelId,
    //     }).then(({ data }) => {

    //       if (data.status) {
    //         setProgress(false);
    //         // console.log("RAW DATA:", data.data);
    //         setLevelUsersList(data.data);
    //       }
    //     }).catch(err => {
    //         setProgress(false);
    //       console.log(err);
    //     });
    // }

    // const tabHandler = (newValue = 0) => {
    //     const levelOne = levelUsersList.filter((val) => {
    //         return val.dkd_commission_struct_id == 1;
    //     });
    //     const levelTwo = levelUsersList.filter((val) => {
    //         return val.dkd_commission_struct_id == 2;
    //     });
    //     const levelThree = levelUsersList.filter((val) => {
    //         return val.dkd_commission_struct_id == 3;
    //     });
    //     const levelFour = levelUsersList.filter((val) => {
    //         return val.dkd_commission_struct_id == 4;
    //     });
    //     const levelFive = levelUsersList.filter((val) => {
    //         return val.dkd_commission_struct_id == 5;
    //     });
    //     // alert(newValue);
    //     let dwnlvIds = [];
    //     if(newValue == 1) {
    //         setDownlevelUser(levelOne);
    //     } else if (newValue == 2) {
    //         setDownlevelUser(levelTwo);
    //     } else if (newValue == 3) {
    //         setDownlevelUser(levelThree);
    //     } else if (newValue == 4) {
    //         setDownlevelUser(levelFour);
    //     } else if (newValue == 5) {
    //         setDownlevelUser(levelFive);
    //     }
    // }

    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = downlevelUser.filter((user) => {
                return (user.name || '').toLowerCase().includes((keyword || '').toLowerCase())
                    || (user.user_unique_id || '').toLowerCase().includes((keyword || '').toLowerCase())
                    || (user.email || '').toLowerCase().includes((keyword || '').toLowerCase())
                    || (user.phone || '').toLowerCase().includes((keyword || '').toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setSearchData(results);
        } else {
            setSearchData(downlevelUser);
            // If the text field is empty, show all users
        }

        setSearchKeyword(keyword);
    };


    const fetchStudentsList = async () => {

        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.get(config.rootUrl + "/user/downlevelUsers").then(({ data }) => {

            if (data.status) {
                setDownlevelUserList(data.data)
                
                // const levelOne = data.data.filter((val) => {
                //     return val.dkd_commission_struct_id == 1;
                // });
                // const levelTwo = data.data.filter((val) => {
                //     return val.dkd_commission_struct_id == 2;
                // });
                // const levelThree = data.data.filter((val) => {
                //     return val.dkd_commission_struct_id == 3;
                // });
                // const levelFour = data.data.filter((val) => {
                //     return val.dkd_commission_struct_id == 4;
                // });
                // const levelFive = data.data.filter((val) => {
                //     return val.dkd_commission_struct_id == 5;
                // });

            }
        }).catch(err => {

            console.log(err);
        });
    }

    const fetchLevelsList = async () => {
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.get(config.rootUrl + "/getDkdLevel").then(({ data }) => {
            if (data.status) {
                setLevelList(data.data);
                setTabIndex(data.data[0].id)
                console.log(data.data[0].id)
            }
        }).catch(err => {
            console.log(err);
        });
    }
    const fetchAll = async () => {
        await fetchLevelsList()
        await fetchStudentsList();
        return true
    }
    useEffect(() => {
        setProgress(true);
        fetchAll().then((completed) => {
            setProgress(false);

        }).catch(err => {
            console.log("Fetch err", err);
            setProgress(false);
        })

    }, []);
    
    useEffect(() => {
        if(Object.keys(downlevelUserList).length !== 0){
            if (tabIndex == 2) {
                setDownlevelUser(downlevelUserList.stateCo);
            } else if (tabIndex == 3) {
                setDownlevelUser(downlevelUserList.districtCo);
            } else if (tabIndex == 4) {
                setDownlevelUser(downlevelUserList.blockCo);
            } else if (tabIndex == 5) {
                setDownlevelUser(downlevelUserList.studentCo);
            }
        }
    }, [tabIndex, downlevelUserList])
    if (progress) {
        return <MyLoader visible={progress} onclose={() => setProgress(!progress)} />
    }

    const packagePath = id => {
        // history.push({
        //     pathname: '/packages',
        //     search: `?id=${id}`,
        // })
        history.push(`packages/${id}`);
    };


    // const MinusSquare = (props) => {
    //     return (
    //       <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
    //         {/* tslint:disable-next-line: max-line-length */}
    //         <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    //       </SvgIcon>
    //     );
    //   }
      
    //   const PlusSquare = (props) => {
    //     return (
    //       <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
    //         {/* tslint:disable-next-line: max-line-length */}
    //         <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    //       </SvgIcon>
    //     );
    //   }
      
    //   const CloseSquare = (props) => {
    //     return (
    //       <SvgIcon
    //         className="close"
    //         fontSize="inherit"
    //         style={{ width: 14, height: 14 }}
    //         {...props}
    //       >
    //         {/* tslint:disable-next-line: max-line-length */}
    //         <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    //       </SvgIcon>
    //     );
    //   }
      
      
    //   const StyledTreeItem = styled((props) => (
    //     <TreeItem {...props} />
    //   ))(({ theme }) => ({
    //     [`& .${treeItemClasses.iconContainer}`]: {
    //       '& .close': {
    //         opacity: 0.3,
    //       },
    //     },
    //     [`& .${treeItemClasses.group}`]: {
    //       marginLeft: 15,
    //       paddingLeft: 18,
    //       borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    //     },
    //   }));

    return (
        <div>
            {/* <div style={{marginTop: "70px"}}>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    sx={{ height: "auto", flexGrow: 1, width: "100%", overflowY: 'auto' }}
                >
                    {console.log(treeData)  }
                    {
                        treeData.length > 0 && treeData.map((stateUser, su) => (
                            <TreeItem nodeId={su+1} label={`${stateUser.name} ${`Admin`} ${su+1}`}>
                               <GetClildTree list={stateUser.all_children} />
                               {
                                    stateUser.all_children.length > 0 && 
                                    stateUser.all_children.map((districtUser, du) => (
                                        <TreeItem nodeId={`${su+1}a${du}`} label={`${districtUser.name} (District)`}>
                                            {
                                                districtUser.all_children.length > 0 && 
                                                districtUser.all_children.map((blockUser, bu) => (
                                                    <TreeItem nodeId={`${su+1}a${du+1}a${bu}`} label={`${blockUser.name} (Block)`}>
                                                        {
                                                            blockUser.all_children.length > 0 && 
                                                            blockUser.all_children.map((studentCoUser, stcu) => (
                                                                <TreeItem nodeId={`${su+1}a${du+1}a${bu}a${stcu}`} label={`${studentCoUser.name} (Student Co)`}>
                                                                    {
                                                                        studentCoUser.all_children.length > 0 && 
                                                                        studentCoUser.all_children.map((studentUser, stu) => (
                                                                            // <TreeItem nodeId={`${su+1}a${du+1}a${bu}a${stcu}a${stu}`} label={`${studentUser.name} (Student)`}>
                                                                                <TreeItem nodeId={`${su+1}a${du+1}a${bu}a${stcu}a${stu}`} label={`${studentUser.name} (Student)`} />
                                                                            // </TreeItem>
                                                                        )) 
                                                                    }
                                                                </TreeItem>
                                                            )) 
                                                        }
                                                    </TreeItem>
                                                )) 
                                            }
                                        </TreeItem>
                                    )) 
                                }
                            </TreeItem>
                        ))
                    }
                    <TreeItem nodeId="110" label="Chrome">
                            <TreeItem nodeId="111" label="Chrome" />
                    </TreeItem>
                </TreeView>
            </div> */}
            <div className='TabContainer'>
                <Box sx={{ bgcolor: 'background.paper', width: "100%" }} mt={2}>
                    <AppBar position="static">
                        <Tabs value={tabIndex} onChange={handleTabChange}
                            centered
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example">
                            {
                                levelList.map((item, i) => {
                                    return <Tab value={item.id} key={item.id} label={item.level} sx={{ fontSize: "12px" }} />
                                })
                            }
                            {/* <Tab label="Unsubscribe" />
                            <Tab label="Renewal" />
                            <Tab label="Expire" />
                            <Tab label="Expire" />
                            <Tab label="Expire" /> */}
                        </Tabs>
                    </AppBar>

                    <div className='SearchBox'>
                        <Input
                            autoComplete='off'
                            value={searchKeyword}
                            onChange={filter}
                            placeholder="Search ..."
                            id="input-with-icon-adornment"
                            sx={{ width: "100%" }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </div>

                </Box>
            </div>
            <div className='ListBox'>
                <Grid item xs={12} md={2}>
                    <br />
                    <List dense={true}>
                        {
                            (Object.keys(downlevelUser).length !== 0) ?
                                (searchKeyword.length > 0) ?
                                    searchData.map((student, i) => {
                                        return <DownlevelList key={i} student={student} onclick={() => {
                                            setOpen(!open);
                                            setSelectedUser(student);
                                        }}
                                            onclickBuy={() => { }}
                                        />
                                    })
                                    :
                                    downlevelUser.map((student, i) => {
                                        return <DownlevelList key={i} student={student} onclick={() => {
                                            setOpen(!open);
                                            setSelectedUser(student);
                                        }}
                                            onclickBuy={() => { }}
                                        />
                                    })
                                : <React.Fragment>
                                    <div className='NotFound'>
                                        <img src='../assets/images/nrf.png' width="100%" />
                                        <br />
                                        <br />
                                        <Typography align="center" color="primary" gutterBottom variant="subtitle1" component="div">
                                            No Data Found!
                                        </Typography>
                                    </div>
                                </React.Fragment>
                        }
                    </List>

                </Grid>
            </div>
            <Drawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer}
                PaperProps={{
                    sx: { width: "100%" },
                }}
            >
                {
                    (Object.keys(selectedUser).length !== 0) ?
                        <DownlevelDetails user={selectedUser} onClickBuy={() => packagePath(selectedUser.id)} />
                        : ''
                }
            </Drawer>
            <div>
                {
                    (user.dkd_commission_struct_id == 2 || user.dkd_commission_struct_id == 3)
                        ?
                        <></>
                        :
                        <Fab aria-label="Add" color="primary" sx={fabStyle} onClick={() => history.push('/member/downlevel-create')}>
                            <AddIcon />
                        </Fab>
                }
            </div>
        </div>
    )
}

// const GetClildTree = ({childs = []}) => {
//     const [isLoading, setIsLoading] = useState(true);
//     useEffect(() => {
//         setTimeout(()=>{
//             setIsLoading(false);
//         }, 1500)
//     }, [])       
//     if(isLoading) {
//         return (<p>Loading...</p>)
//     } else {
//         return (
//             <>
//              {
//                 childs.length > 0 && 
//                 childs.map((child, c) => (
//                     <TreeItem nodeId={c} label={`${child.name} (District)`}>
//                         <TreeItem nodeId="2" label="Calendar" />
//                     </TreeItem>
//                 )) 
//             }
//             </>
//         )
//     }
// }

export default DownlevelHome;