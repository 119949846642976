import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MySnackbar = ({data = {}, visible = false , handleClose = () => {}, ...rest}) => {
    

  
    const action = (
      <React.Fragment>
        <Button color="secondary" size="small" onClick={handleClose}>
          Close
        </Button>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
  
    return (
      <div>
        <Snackbar
          open={visible}
          autoHideDuration={6000}
          onClose={handleClose}
          // action={action}
        >
          <Alert onClose={handleClose} severity={data.type} sx={{ width: '100%' }}>
            {data.label}
          </Alert>
        </Snackbar>
      </div>
    );
}

export default MySnackbar;