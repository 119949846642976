import React, { useState, useEffect } from 'react';

import { 
    Typography, 
    Fab,
    List,
    Grid,
    Drawer,
    Tabs,
    Tab,
    Box,
    AppBar,
    Input,
    InputAdornment
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory  } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import config from '../../core/config';
import MyLoader from './../../components/MyLoader';
import StudentList from './StudentList';
import StudentDetails from './StudentDetails';



const StudentHome = (props) => {
    
    let history = useHistory();
    const [students, setStudents] = useState([]);
    const [progress, setProgress] = useState(false);

    const [open, setOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedStudent, setSelectedStuden] = useState([]);
    
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchData, setSearchData] = useState([]);

    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: 16,
    };


    const toggleDrawer = (event) => {
        setOpen(!open);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        fetchStudentsList(newValue);
        setSearchKeyword("")
    };

    const filter = (e) => {
        const keyword = e.target.value;
    
        if (keyword !== '') {
          const results = students.filter((user) => {
            return user.name.toLowerCase().includes(keyword.toLowerCase()) 
            || user.user_unique_id.toLowerCase().includes(keyword.toLowerCase()) 
            || user.iclass_name.toLowerCase().includes(keyword.toLowerCase())
            || user.phone.toLowerCase().includes(keyword.toLowerCase());
            // Use the toLowerCase() method to make it case-insensitive
          });
          setSearchData(results);
        } else {
          setSearchData(students);
          // If the text field is empty, show all users
        }
    
        setSearchKeyword(keyword);
    };


    const fetchStudentsList = async (newValue = 0) => {
        setProgress(true);
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.get(config.rootUrl + "/student").then(({ data }) => {

          if (data.status) {
            setProgress(false);
              
            const unsub = data.data.filter((val) => {
                return val.is_renewal == 0;
            });
            
            const renewal = data.data.filter((val) => {
                return val.is_renewal == 1;
            });
            
            const expire = data.data.filter((val) => {
                return val.is_renewal == 2;
            });
            
            if(newValue == 0) {
                setStudents(unsub);
            } else if (newValue == 1) {
                setStudents(renewal);
            } else if (newValue == 2) {
                setStudents(expire);
            }
          }
        }).catch(err => {
            setProgress(false);
          console.log(err);
        });
    }

    var  stuListData =  [];
    useEffect(() => {
        fetchStudentsList(tabIndex);
        
    }, []);

    if (progress) {
        return <MyLoader visible={progress}  onclose={() => setProgress(!progress) } />
    }

    const packagePath = id => {
        // history.push({
        //     pathname: '/packages',
        //     search: `?id=${id}`,
        // })
        history.push(`packages/${id}`);
    };

    return (
        <div>
            <div className='TabContainer'>
                <Box sx={{ bgcolor: 'background.paper', width: "100%"}} mt={2}>
                    <AppBar position="static">
                        <Tabs value={tabIndex} onChange={handleTabChange} 
                        centered 
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example">
                            <Tab label="Unsubscribe" />
                            <Tab label="Renewal" />
                            <Tab label="Expire" />
                        </Tabs>
                    </AppBar>

                    <div className='SearchBox'>
                        <Input
                            autoComplete='off'
                            value={searchKeyword}
                            onChange={filter}
                            placeholder="Search ..."
                            id="input-with-icon-adornment"
                            sx={{width: "100%"}}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </div>
                    
                </Box>
            </div>
            <div className='ListBox'>
                <Grid item xs={12} md={2}>
                        <br />
                        <List dense={true}>
                        {   
                            (Object.keys(students).length !== 0) ?
                                (searchKeyword.length > 0)  ?
                                    searchData.map((student, i) => {
                                        return <StudentList key={i} student={student} onclick={()=>{
                                                setOpen(!open);
                                                setSelectedStuden(student);
                                            }} 
                                            onclickBuy={() => packagePath(student.id)}
                                        />
                                    })
                                :
                                    students.map((student, i) => {
                                        return <StudentList key={i} student={student} onclick={()=>{
                                                setOpen(!open);
                                                setSelectedStuden(student);
                                            }} 
                                            onclickBuy={() => packagePath(student.id)}
                                        />
                                    }) 
                            : <React.Fragment>
                                <div className='NotFound'>
                                    <img src='../assets/images/nrf.png' width="100%" />
                                    <br />
                                    <br />
                                    <Typography align="center" color="primary" gutterBottom variant="subtitle1" component="div">
                                        No Data Found!
                                    </Typography>
                                </div>
                            </React.Fragment>
                        }
                        </List>
                        
                </Grid>
            </div>
            <Drawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer}
                PaperProps={{
                    sx: { width: "100%" },
                }}
            >
                {
                    (Object.keys(selectedStudent).length !== 0) ? 
                        <StudentDetails student={selectedStudent} onClickBuy={()=> packagePath(selectedStudent.id)} />
                    : ''
                }
            </Drawer>
            <div>
                <Fab aria-label="Add" color="primary" sx={fabStyle} onClick={()=> history.push('/member/studentCreateAndEdit')}>
                    <AddIcon />
                </Fab>
            </div>
        </div>
    )
}

export default StudentHome;