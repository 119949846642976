import React, { useState, useNavigate } from 'react'
// import {useSelector } from 'react-redux'
import { useHistory  } from 'react-router-dom';
import { make_logout } from '../../store/actions/AuthActions';

import MyAppBar from '../../components/MyAppBar';
import { Box, Drawer, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, Typography} from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import config from '../../core/config';
import { useDispatch, useSelector } from 'react-redux';


import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import { connect } from 'react-redux';

const Header = (props) => {
    let history = useHistory();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const auth = useSelector((state) => { return state.auth });

    const drawerItemListData = [
        {label: "Dashboard", icon: <DashboardIcon sx={{ color: "#292c3c" }} />, uri: '/member/dashboard'},
        {label: "Profile", icon: <InboxIcon sx={{ color: "#292c3c" }} />, uri: '/member/profile'},
        {label: "Coordinators", icon: <SupervisorAccountIcon sx={{ color: "#292c3c" }} />, uri: '/member/downlevel'},
        {label: "Students", icon: <PersonIcon sx={{ color: "#292c3c" }} />, uri: '/member/student'},
        {label: "Wallet", icon: <AccountBalanceWalletIcon sx={{ color: "#292c3c" }} />, uri: '/member/wallet'},
        {label: "Withdraw", icon: <AccountBalanceWalletIcon sx={{ color: "#292c3c" }} />, uri: '/member/wallet'},
        {label: "Commission History", icon: <CurrencyRupeeIcon sx={{ color: "#292c3c" }} />, uri: '/member/commission-history'},
    ];

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(!open);
    };

    const MyDrawerItem = ({label = "", uri = "", children, ...rest}) => {
        return (
            <ListItem key={label} disablePadding onClick={() => history.push(uri)}>
                <ListItemButton>
                    <ListItemIcon>
                       {children} 
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography sx={{fontSize: "18px"}} variant="subtitle1" color="#292c3c" display="block">
                            {label}
                        </Typography>
                    } />
                </ListItemButton>
            </ListItem>
        );
    }

    const myList = (anchor = "left") => (
        <div className="drawerBox">
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : "100%" }}
                role="presentation"
                onClick={toggleDrawer}
                onKeyDown={toggleDrawer}
            >
                <div className="drawerMainContent">
                    <div className="drawerAvaterImg">
                        <img src={"https://astergo.in/avatar.png"} width="100%" />
                    </div>
                    <Typography variant="h6" align="center" color="white" component="div" sx={{ flexGrow: 1 }}>
                        {auth.user.name ?? '--'}
                        {/* USER */}
                    </Typography>
                    <Typography variant="subtitle1" align="center" color="white" component="div" sx={{ flexGrow: 1 }}>
                    <span style={{color: "#ffc107"}}>Referral Code: {auth.user.user_unique_id ?? 'N/A'}</span>
                        {/* NA */}
                    </Typography>
                </div>
                <List>
                {drawerItemListData.map((item, index) => (
                    <MyDrawerItem key={index} label={item.label} uri={item.uri}>
                        {item.icon}
                    </MyDrawerItem>
                ))}
                </List>
                <Divider />
            </Box>
        </div>
    );

    const menuItems = [
        {title: "Profile", action : () => {history.push('/member/profile')}},
        {title: "My account", action : () => {}},
        {title: "Logout", action : () => {dispatch(make_logout())}},
    ];
    
    return (
        <div>
            <Drawer
                anchor="left"
                open={open}
                onClose={toggleDrawer}
                PaperProps={{
                    sx: { width: "300px" },
                }}
            >
                {myList("left")}
            </Drawer>
            <MyAppBar toggleDrawer={toggleDrawer} items={menuItems} />
        </div>
    );

}

//<a onClick={ () => { this.props.make_logout() } } className="dropdown-item" href="#"><i className="dw dw-logout" /> Log Out</a>
// const mapStateToProps =(state)=>({
//     auth : state.auth
// })
// export default connect(mapStateToProps,null)( Header);
export default Header;