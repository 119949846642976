import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const MyLoader = ({visible = true, onclose = () => {}}) => {
  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={visible}
        onClick={onclose}
        >
        <CircularProgress color="primary" />
    </Backdrop>
  )
}

export default MyLoader;