import React, { useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import LogoDark from '../../assets/app-logo.png';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../Dashboard';
import Profile from '../Profile';
import StudentHome from './../Student/StudentHome';
import StudentCreateAndEdit from './../Student/StudentCreateAndEdit';
import PackageList from './../Package/PackageList';
import Payment from '../Payment';
import CreateDownlevel from './../Downlevel/CreateDownlevel';
import DownlevelHome from './../Downlevel/DownlevelHome';
import Wallet from '../Wallet';
import PayStatus from './../Payment/PayStatus';
import CommissionHome from './../commission/CommissionHome';

const Master = () => {



    return (
        <>
            
            <Header />

            {/* <Sidebar /> */}
            <Switch>
                {/* <Route exact path="/member" render={(props) => { <Redirect to="/member/dashboard" /> }} /> */}
                <Route path="/member/dashboard" component={Dashboard} />
                <Route path="/member/profile" component={Profile} />
                <Route path="/member/student" component={StudentHome} />
                <Route path="/member/studentCreateAndEdit" component={StudentCreateAndEdit} />
                <Route path="/member/packages/:id" component={PackageList} />
                <Route path="/member/payment/:id/:amount" component={Payment} />
                {/* <Route path="/member/pay-status/:status/:type/:month_id" component={PayStatus} /> */}
                <Route path="/member/pay-status/:status/:type/:month_id/:sub_id" component={PayStatus} />
                <Route path="/member/downlevel" component={DownlevelHome} />
                <Route path="/member/downlevel-create" component={CreateDownlevel} />
                <Route path="/member/wallet" component={Wallet} />
                <Route path="/member/commission-history" component={CommissionHome} />
            </Switch>

        </>
    )
}

export default Master;
