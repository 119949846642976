


const config = {
    // rootUrl : `http://192.168.29.55:8000/api/v1/dkd`,
    // rootUrlV1 : `http://192.168.29.55:8000/api/v1`,
    rootUrl : `https://portal.vidyarthiindia.in/api/v1/dkd`,
    rootUrlV1 : `https://portal.vidyarthiindia.in/api/v1`,
    // rootUrl : `http://192.168.29.55:8000/api/v1/dkd`,
    // rootUrlV1 : `http://192.168.29.55:8000/api/v1`,
    // rootUrl : `http://localhost:8000/api/v2/portal`,
    // jwt_secret : 'boyrOxkTHzYl0JyqbbB5rjD9T1lggqC9Kgshrzak3dvD5QoHinSPbKcegTnXXruP',

    // rootUrl : `http://localhost:8000/api`,
    // jwt_secret : 'kYMGuqXGmTSRSyYA3T198twPfyY5TKCbcrTJ6ZzT5UqXd0KHwXhePLYzz79PLNL8',
    payment_host :"https://portal.vidyarthiindia.in",

    colors: {
        red: "red",
        primary: {
            light: '#18326da8',
            main: '#0c0058',
            dark: '#00081A',
            gradient: 'linear-gradient(19deg, #021f3f, #031847)',
        },
        secondary: {
            light: '#ffebce',
            main: '#ffc107',
            dark: '#FF8000',
            contrastText: '#ffcc00',
        },
    },
}
export default config;