import React from 'react';

import { 
    Typography, 
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Grid,
    IconButton,
    Divider,
    Stack,
    Chip,
    Box
} from '@mui/material';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const DownlevelDetails = ({user ={}, onClickBuy = () => {}, ...rest}) => {

    return <React.Fragment>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} mb={2}>
                <List dense={true}>
                    <ListItem
                        // secondaryAction={
                        //     <IconButton edge="end" aria-label="buy" onClick={onClickBuy}>
                        //         <ShoppingBasketIcon color="primary" sx={{ fontSize: 30 }} />
                        //     </IconButton>
                        // }
                    >
                        <React.Fragment>
                            <ListItemAvatar>
                                <Avatar alt="Cindy Baker" src={user.image} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="subtitle2"
                                            color="text.primary"
                                        >
                                        {user.name}
                                        </Typography>
                                    </React.Fragment>
                                }
                                secondary={ <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                    Reference Code : {user.user_unique_id}
                                    </Typography>
                                </React.Fragment>
                                }
                            />
                        </React.Fragment>
                    </ListItem>
                    {/* <ListItem>
                        <ListItemText
                            primary="Board"
                            // primary={`Class : ${user.iclass_name}`}
                            secondary={user.board_name}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Class"
                            // primary={`Class : ${user.iclass_name}`}
                            secondary={user.iclass_name}
                        />
                    </ListItem> */}
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="buy" onClick={()=>{
                                window.open(`tel:${user.phone}`, '_self');
                            }}>
                                <AddIcCallIcon color="primary" sx={{ fontSize: 30 }} />
                            </IconButton>
                        }
                    >
                        <ListItemText
                            primary="Phone"
                            // primary={`Phone : ${user.phone}`}
                            secondary={user.phone}
                            
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Email"
                            // primary={`Date of Birth : ${user.dob}`}
                            secondary={user.email ?? 'NA'}
                        />
                    </ListItem>
                    {
                        user?.parent && user?.parent != null &&
                        <ListItem>
                            <ListItemText
                                primary="Report to"
                                // primary={`Date of Birth : ${user.dob}`}
                                secondary={user?.parent.name ?? 'NA'}
                            />
                        </ListItem>
                    }
                    {/* <ListItem>
                        <ListItemText
                            primary="Date of Birth "
                            // primary={`Date of Birth : ${user.dob}`}
                            secondary={user.dob ?? 'NA'}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Gender"
                            // primary={`Gender : ${user.gender}`}
                            secondary={user.gender}
                        />
                    </ListItem> */}
                    {/* <Divider />
                    <Box sx={{ m: 2 }}>
                        <Typography gutterBottom variant="subtitle1">
                        Packages
                        </Typography>
                            {
                                months.map( (month, i) => {
                                    return (i % 2 == 0) ? <div className="ChipBox"><Chip key={i} color="primary" label={month} /></div> : <div className="ChipBox"><Chip key={i} label={month} /></div>  
                                })
                            }
                    </Box> */}
                </List>
            </Grid>
        </Grid>
    </React.Fragment>
}

export default DownlevelDetails;