import React, {Fragment} from 'react';
import { 
  Typography, 
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,

} from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';


import WomanAvatar from '../../assets/woman.png';
import ManAvatar from '../../assets/man.png';


const DownlevelList = ({student = {}, onclick = () => {}, onclickBuy = () => {}, ...rest}) => {
  return (
    <React.Fragment>
        <Divider />
        <ListItem
            secondaryAction={
                // <IconButton edge="end" aria-label="buy" onClick={onclickBuy}>
                //     <ShoppingBasketIcon color="primary" sx={{ fontSize: 40 }} />
                // </IconButton>

                <Fragment>
                    <Chip label={`Self:${student.commission_track?.total_student_added ?? '0'}`} size="small" color="success" variant="filled" />&nbsp;
                    {
                        student.dkd_commission_struct_id < 5 && 
                        <Chip label={`Team:${student.commission_track?.total_student_added_by_tm ?? '0'}`} size="small" color="primary" variant="filled" />
                    }
                </Fragment>
            }
        >
            <React.Fragment>
                <ListItemAvatar>
                    <Avatar alt="vi user" src={student.image != null && student.image.length > 10 ? student.image : student.gender == "Female" ? WomanAvatar : ManAvatar} />
                </ListItemAvatar>
                <ListItemText
                    onClick={onclick}
                    primary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="subtitle2"
                                color="text.primary"
                            >
                            {student.name} 
                            {/* <Chip label={student.iclass_name} size="small" color="secondary" variant="outlined" /> */}
                            </Typography>
                        </React.Fragment>
                    }
                    secondary={ <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                        UUID : {student.user_unique_id}
                        </Typography>
                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                    </React.Fragment>
                    }
                />
            </React.Fragment>
        </ListItem>
        {/* <Divider variant="inset" component="li" /> */}
        <Divider />
    </React.Fragment>
  )
}

export default DownlevelList;