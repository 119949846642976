import React from 'react'
import {
    Box, 
    AppBar, 
    Menu, 
    MenuItem, 
    Toolbar, 
    IconButton, 
    Typography,
    Tooltip,
    Avatar
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
// import { useSelector } from 'react-redux';


const MyAppBar = ({toggleDrawer = () => {}, items, ...rest}, ) => {
    // const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const user = useSelector((state) => { return state.auth.user });
  
    // const handleChange = (event) => {
    //   setAuth(event.target.checked);
    // };
  
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={(e)=>toggleDrawer(e)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Vidyarthi<span style={{color: "#ffc107"}}>india</span> - DKD 
            </Typography>
              <div>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src={`https://astergo.in/avatar.png`} />
                    {/* <Avatar alt="Remy Sharp" src={user.image != null ? user.image : `https://astergo.in/avatar.png`} /> */}
                  </IconButton>
                </Tooltip>
                {/* <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton> */}
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {
                    items.map((item, i) => {
                      return <MenuItem key={i} onClick={()=>{
                        item.action();
                        handleClose();
                      }}>{item.title }</MenuItem>
                    })
                  }
                </Menu>
              </div>
          </Toolbar>
        </AppBar>
      </Box>
    );
}


export default MyAppBar;