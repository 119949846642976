import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { HashRouter as Router, Redirect, Switch } from 'react-router-dom';
import Login from './components/Login';
// import Dashboard from './views/Dashboard';
import GuestRoute from './routes/GuestRoute';
import AuthRoute from './routes/AuthRoute';
import Home from './views/Home';
import Master from './views/layouts/Master';
// import Profile from './views/Profile';
import config from './core/config';
import { createTheme, ThemeProvider } from '@mui/material/styles';



const theme = createTheme({
    palette: {
      primary: {
        light: config.colors.primary.light,
        main: config.colors.primary.main,
        dark: config.colors.primary.dark,
      },
      secondary: {
        light: config.colors.secondary.light,
        main: config.colors.secondary.main,
        dark: config.colors.secondary.dark,
        contrastText: config.colors.secondary.contrastText,
      },
      custom: {
        light: '#ffa726',
        main: '#f57c00',
        dark: '#ef6c00',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      }
    },
});

function App() {
 
  return (
    <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <GuestRoute exact path='/' component={Login} />
            {/* <GuestRoute exact path='/' component={Home} /> */}
            <GuestRoute path="/login" component={Login} />
            <GuestRoute path="/home" render={(props) => { <Redirect to="/" /> }} />
            
            <AuthRoute path="/member" component={Master} />
          </Switch>
        </Router>
    </ThemeProvider>
  );
}

export default App;
