import React from 'react';

import { 
    Typography, 
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Grid,
    IconButton,
    Divider,
    Stack,
    Chip,
    Box
} from '@mui/material';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const StudentDetails = ({student ={}, onClickBuy = () => {}, ...rest}) => {

    return <React.Fragment>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} mb={2}>
                <List dense={true}>
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="buy" onClick={onClickBuy}>
                                <ShoppingBasketIcon color="primary" sx={{ fontSize: 30 }} />
                            </IconButton>
                        }
                    >
                        <React.Fragment>
                            <ListItemAvatar>
                                <Avatar alt="Cindy Baker" src={student.image} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="subtitle2"
                                            color="text.primary"
                                        >
                                        {student.name}
                                        </Typography>
                                    </React.Fragment>
                                }
                                secondary={ <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                    UUID : {student.user_unique_id}
                                    </Typography>
                                    {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                </React.Fragment>
                                }
                            />
                        </React.Fragment>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Coordinator"
                            // primary={`Class : ${student.iclass_name}`}
                            secondary={<>{student.parent.name ?? ''} - <i>{student.parent.user_unique_id}</i></>}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Board"
                            // primary={`Class : ${student.iclass_name}`}
                            secondary={student.board_name}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Class"
                            // primary={`Class : ${student.iclass_name}`}
                            secondary={student.iclass_name}
                        />
                    </ListItem>
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="buy" onClick={()=>{
                                window.open(`tel:${student.phone}`, '_self');
                            }}>
                                <AddIcCallIcon color="primary" sx={{ fontSize: 30 }} />
                            </IconButton>
                        }
                    >
                        <ListItemText
                            primary="Phone"
                            // primary={`Phone : ${student.phone}`}
                            secondary={student.phone}
                            
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Email"
                            // primary={`Date of Birth : ${student.dob}`}
                            secondary={student.email ?? 'NA'}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Date of Birth "
                            // primary={`Date of Birth : ${student.dob}`}
                            secondary={student.dob ?? 'NA'}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Gender"
                            // primary={`Gender : ${student.gender}`}
                            secondary={student.gender}
                        />
                    </ListItem>
                    <Divider />
                    <Box sx={{ m: 2 }}>
                        <Typography gutterBottom variant="subtitle1">
                        Packages
                        </Typography>
                        {/* <Stack direction="row" spacing={1} sx={{width: 200}}> */}
                            {
                                months.map( (month, i) => {
                                    return (Object.keys(student.subscriptions).length !== 0 && student.subscriptions[i].status > 0 ) ? <div className="ChipBox"><Chip key={i} color="primary" label={month} /></div> : <div className="ChipBox"><Chip key={i} label={month} /></div>  
                                })
                            }
                        {/* </Stack> */}
                    </Box>
                </List>
            </Grid>
        </Grid>
    </React.Fragment>
}

export default StudentDetails;