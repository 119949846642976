import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import cookie from 'js-cookie';
import { connect } from 'react-redux';

const GuestRoute = ({ component: Component, ...rest }) => {
    // const token = cookie.get('token')
    const redirectAfterLogin = '/member/dashboard';
    return (
        (
            <Route 
                {...rest} 
                render={ props => (
                    !rest.loggedIn ? (
                        <Component {...props} />
                    ) : ( 
                        <Redirect 
                            to={{ 
                                pathname : redirectAfterLogin,
                                state : { from: props.location }
                             }} 
                        />
                    )
                )} 
            />
        )
    )
}

const mapStateToProps = state => {
    return {
        loggedIn: state.auth.isAuth
    };
}; 

export default connect(mapStateToProps)(GuestRoute);