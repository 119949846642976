import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Home extends Component {
    render() {
        return (
            <div>
                <h1>Welcome to home!</h1>
                <br />
                <Link to='/login' >Login</Link>
            </div>
        )
    }
}
