import React, { useState, useEffect } from 'react';
import { Paper, Box, Grid, Card, CardActionArea, CardContent, Typography, LinearProgress, Button, Stack, Chip, Divider } from '@mui/material';
import config from './../../core/config';
import axios from "axios";
import MyLoader from './../../components/MyLoader';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { setWallet } from '../../store/actions/WalletActions';

const PackageList = (props) => {
    const [progress, setProgress] = useState(false);
    const [packages, setPackages] = useState([]);
    const dispatch = useDispatch();
    const user = useSelector((state) => { return state.auth.user })
    const { id } = useParams();
    const wallet = useSelector(s=>s.wallet)


    // const fetchPackageList = async () => {
    //     setProgress(true);
    //     var token = localStorage.getItem('token');
    //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    //     await axios.post(config.rootUrl + "/getPackages", {
    //         user_id: id
    //     }).then(({ data }) => {
    //         console.log(data.data);
    //       if (data.status) {
    //         setProgress(false);
    //         setPackages(data.data)
    //       }
    //     }).catch(err => {
    //         setProgress(false);
    //       console.log(err);
    //     });
    // }
    
    const fetchStudent = async () => {
        setProgress(true);
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.get(`${config.rootUrl}/student/view/${id}`).then(({ data }) => {
            console.log(data.data.user_subscription);
          if (data.status) {
            setProgress(false);
            setPackages(data.data.user_subscription)
          }
        }).catch(err => {
            setProgress(false);
          console.log(err);
        });
    }


    const openPaymentGateway = async(item)=>{
        // window.location.replace(`http://192.168.29.55:8000/test-payment/${Base64.encode(id)}/${Base64.encode(item.amount)}`)
        if(window.confirm("Are you sure to purchase this package ?")){
            if (wallet < parseFloat(item.amount)) {
                alert('Insufficiant Balance');
                props.history.push('/member/wallet')
              } else {
                // console.log(`/member/pay-status/${1}/${'wallet'}/${item.months.id}`);
                //   props.history.push(`/member/pay-status/${1}/${'wallet'}/${item.months.id}/${item.id}`)
                setProgress(true);
                await axios.post(config.rootUrlV1 + "/packageSubscribe" , {
                    month_id : item.months.id,
                    sub_id : item.id,
                }).then(({data})=>{
                    console.log(data);
                    if(data.status){
                        alert(data.msg);
                        window.location.reload();
                    }else{
                        
                    }
                    setProgress(false);
                }).catch((err)=>{
                    console.log(err);
                    setProgress(false);
                })
                await dispatch(setWallet('api'));
              }
            // props.history.push(`/member/payment/${id}/${item.amount}`)
        }
    }
    useEffect(() => {
        // fetchPackageList();
        fetchStudent();
        console.log(id);
        
    }, []);

    if (progress) {
        return <MyLoader visible={progress}  onclose={() => setProgress(!progress) } />
    }

    return (
        <div className='main '>
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={1.5}>
                    {/* <Grid item xs={12}>
                        <Card sx={{ maxWidth: "100%", background: config.colors.secondary.light }} variant="outlined">
                            <CardContent>
                                <Typography align="left" gutterBottom variant="subtitle2" color="primary" component="div">
                                    Active Plan
                                </Typography>
                                <Typography align="left" gutterBottom variant="h6" color={config.colors.secondary.dark} component="div">
                                    January - ₹149
                                </Typography>
                                <Box sx={{ width: '100%'}}>
                                    <LinearProgress variant="determinate" color="primary" value={20}  />
                                </Box>
                                <Stack spacing={2} direction="column" mt={2}>
                                    <Button onClick={()=>{alert("I want to go home!")}}>More Active Packages</Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid> */}
                    {
                        packages.map((item, i) => {
                            return <Grid key={i} item xs={12}>
                                <Card sx={{ maxWidth: "100%" }}>
                                    <CardActionArea disabled={item.status > 0} onClick={()=>{
                                        item.status == 0 && openPaymentGateway(item)
                                    }}>
                                        <CardContent>
                                            <Stack 
                                                direction="row" 
                                                spacing={1} 
                                                mt={1} 
                                                justifyContent="space-between"
                                            >
                                                <Typography sx={{fontSize: "18px"}} align="left" gutterBottom variant="h6" color={config.colors.primary.dark} component="div">
                                                    {item.months.name ?? ''} <span>{item.iclass.name ?? ''}</span>
                                                    <br/><span style={{fontSize: "15px"}}>Price: ₹{item.amount ?? ''}</span>
                                                </Typography>
                                                {
                                                    (item.status > 0) ? 
                                                    <Chip color="success" label="Active"/>
                                                    : <>
                                                    <Chip color="primary" label="Subscribe"/>
                                                    </>
                                                }
                                                {/* <Chip color="error" label={
                                                    <Typography gutterBottom variant="h6" mb={0}>
                                                        ₹{item.amount}
                                                    </Typography>
                                                }/> */}
                                                
                                            </Stack>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        })
                    }
                </Grid>
            </Box>
        </div>
    )
}

const PackageTile = () => {
    return (
        <Paper elevation={4} >
            <Typography variant="h6" gutterBottom component="div">
                Active Package
            </Typography>
        </Paper>
    );
}


export default PackageList;

