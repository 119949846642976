import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// import jwt from 'jsonwebtoken';
import store from './store/store';
import { Provider } from 'react-redux';
import axios from 'axios';
import config from './core/config';


// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';
// import $ from 'jquery';
// import Popper from '@popperjs/core';
import { setWallet } from './store/actions/WalletActions';



let token = localStorage.getItem('token');
// const jwt_secret = config.jwt_secret;

// if(token) {
//   jwt.verify(token, jwt_secret, (err, decoded) => {
//     if(err){
//       localStorage.removeItem('token');
//       token = null;
//     } else {
//       if(decoded.iss !== `${config.rootUrl}/login`){
//         localStorage.removeItem('token');
//         token = null;
//       }
//     }
//   });
// }

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
}

if(token){
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.get(`${config.rootUrl}/user`).then(res => {
    store.dispatch({ type: 'SET_LOGIN', payload: res.data.data })
    store.dispatch(setWallet('user'))
    renderApp();
  }).catch((err)=>{
    localStorage.clear();
    renderApp();
  })
} else {
  renderApp();
  
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
