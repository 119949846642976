import React, { useState } from 'react';
import { List, ListItem, ListItemText, Typography, Divider } from '@mui/material';
import config from '../../core/config';

import {formatDate} from '../../helpers/index';


// const dateFormate = (inputDate) => {
//     var newDate =  new Date(inputDate);
//     var dd = String(newDate.getDate()).padStart(2, '0');
//     var mm = String(newDate.getMonth() + 1).padStart(2, '0'); //January is 0!
//     var yyyy = newDate.getFullYear();

//     return newDate = mm + '/' + dd + '/' + yyyy;
// }

const StatusType = ({ status = "" }) => {

    if(status == 'pending') {
        return <span style={{
            background: "#ffb10021",
            color: "#d06500",
            borderRadius: "12px",
            padding: "2px 12px",
            fontSize: "14px",
            fontWeight: "600",
        }}>Pending</span>
    } else if(status == 'success') {
        return <span style={{
            background: "#0081051f",
            color: "#007905",
            borderRadius: "12px",
            padding: "2px 12px",
            fontSize: "14px",
            fontWeight: "600",
        }}>Success</span>
    } else {
        return <span style={{
            background: "#ff00001f",
            color: "#e40000",
            borderRadius: "12px",
            padding: "2px 12px",
            fontSize: "14px",
            fontWeight: "600",
        }}>Cancelled</span>
    }
}

const WithdrawList = ({status = null, amount = 0, desc = "", date = "", ...rest}) => {
   return (
        <>
            <List dense={true}>
                <ListItem
                    secondaryAction={ 
                        <>
                            <Typography align="center" gutterBottom variant="subtitle2" color="green" component="div">
                            ₹{amount.toString()}
                            </Typography>
                            <StatusType status={status} />
                        </>
                    }
                disablePadding
                >
                    <ListItemText
                    primary={
                        <Typography align="left" gutterBottom variant="subtitle2" color="success" component="div">
                        {desc}
                        </Typography>
                    }
                    secondary={formatDate(date)}
                    />
                </ListItem>
            </List>
            <Divider />
        </>
   )
};

export default WithdrawList;