import React from 'react';
import { 
  Typography, 
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,

} from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

import WomanAvatar from '../../assets/woman.png';
import ManAvatar from '../../assets/man.png';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const StudentList = ({student = {}, onclick = () => {}, onclickBuy = () => {}, ...rest}) => {
  return (
    <React.Fragment>
        <Divider />
        <ListItem
            secondaryAction={
                <IconButton edge="end" aria-label="buy" onClick={onclickBuy}>
                    <ShoppingBasketIcon color="primary" sx={{ fontSize: 40 }} />
                </IconButton>
            }
        >
            <React.Fragment>
                <ListItemAvatar>
                    <Avatar alt="vi stu" src={student.image != null && student.image.length > 10 ? student.image : student.gender == "Female" ? WomanAvatar : ManAvatar} />
                </ListItemAvatar>
                <ListItemText
                    onClick={onclick}
                    primary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="subtitle2"
                                color="text.primary"
                            >
                            {student.name} <Chip label={student.iclass_name} size="small" color="success" variant="filled" />
                            </Typography>
                        </React.Fragment>
                    }
                    secondary={ <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                        UUID : {student.user_unique_id ?? ''} &nbsp;<br/>Co: {student.parent.name ?? ''} - <i>{student.parent.user_unique_id}</i>
                        <br/>
                        Active Plan:&nbsp;
                        {
                            Object.keys(student.subscriptions).length == 0 ?
                            <span>NA</span>
                            : <>
                                    {
                                        months.map( (month, i) => {
                                            return (<>
                                                {
                                                    (Object.keys(student.subscriptions).length !== 0 && student.subscriptions[i].status > 0 ) && <span key={i} style={{color: 'green'}}>{month}</span> 
                                                }
                                                &nbsp;
                                            </>)  
                                        })
                                    }
                            </>
                        }
                        </Typography>
                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                    </React.Fragment>
                    }
                />
            </React.Fragment>
        </ListItem>
        {/* <Divider variant="inset" component="li" /> */}
        <Divider />
    </React.Fragment>
  )
}

export default StudentList;