import React, { useEffect, useState } from 'react'
import {Box, Button, Grid, Card, CardContent, CardActionArea, Typography, LinearProgress } from '@mui/material';
import config from '../core/config';


import HomeIcon from '@mui/icons-material/Home';
import MyBreadcrumb from './../components/MyBreadcrumb';
import axios from 'axios';
import { useSelector } from 'react-redux';
import MyLoader from '../components/MyLoader';


const breadcrumbs = [
    {label: "Dashboard", uri: null, icon: <HomeIcon fontSize="small" /> },
];

const Dashboard = () => {
    const auth = useSelector((state) => { return state.auth });
    const [progress, setProgress] = useState(10);
    const [commissionHistory, setCommissionHistory] = useState({
        total_commission : {},
        commission_history: []
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const user = useSelector((state) => { return state.auth.user });


    const fetchCommissionHistory = async () => {
        setProgress(true);
        // var token = localStorage.getItem('token');
        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.post(config.rootUrlV1 + "/getCommissionHistory", {
            user_id : user.id,
            type: 5
        }).then(({ data }) => {

          if (data.status) {
            // console.log(data.data);
            setCommissionHistory(data.data);
            setProgress(false);
          }
        }).catch(err => {
          console.log(err);
          setProgress(false);
        });
    }

    useEffect(() => {
        fetchCommissionHistory();
    }, []);

    if (progress) {
        return <MyLoader visible={progress}  onclose={() => setProgress(!progress) } />
    }

    return (
        <div className="main">
            <MyBreadcrumb data={breadcrumbs} />
            <br />
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={1.5}>

                    <Grid item xs={12}>
                        <Card sx={{ maxWidth: "100%" }} style={{backgroundColor: "#0c0058"}}>
                            <CardActionArea>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <CardContent>
                                            <Typography align="center" gutterBottom variant="h3" color="white" component="div">
                                            ₹{parseInt(commissionHistory.total_commission.total_commission_earned)+parseInt(commissionHistory.total_commission.total_commission_earned_by_tm) ?? "0"}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color="white" component="div">
                                            Overall Earning 
                                            </Typography>

                                            {/* <Box sx={{ width: '100%'}}>
                                                <LinearProgress variant="determinate" color="secondary" value={20}  />
                                            </Box> */}
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CardContent>
                                            <Typography align="center" gutterBottom variant="h3" color="white" component="div">
                                            {parseInt(commissionHistory.total_commission.total_student_added)+parseInt(commissionHistory.total_commission.total_student_added_by_tm) ?? 0}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color="white" component="div">
                                            Overall Students 
                                            </Typography>

                                            {/* <Box sx={{ width: '100%'}}>
                                                <LinearProgress variant="determinate" color="secondary" value={20}  />
                                            </Box> */}
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Card sx={{ maxWidth: "100%" }} >
                            <CardActionArea>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <CardContent>
                                            <Typography align="center" gutterBottom variant="h4" color={config.colors.red} component="div">
                                            {commissionHistory.total_commission.total_student_added ?? 0}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.red} component="div">
                                                My Students
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <CardContent className="bl">
                                            <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                            {commissionHistory.total_commission.total_student_added_by_tm ?? 0}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                                Team Students
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Grid> */}
                    
                    <Grid item xs={12}>
                        <Card sx={{ maxWidth: "100%" }} >
                            <CardActionArea>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <CardContent>
                                            <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                            ₹{commissionHistory.total_commission.total_commission_earned ?? "0"}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                                My Earning
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <CardContent className="bl">
                                            <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                            ₹{commissionHistory.total_commission.total_commission_earned_by_tm ?? "0"}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                                Team Earning
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Card sx={{ maxWidth: "100%" }} >
                            <CardActionArea>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <CardContent>
                                            <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                            {commissionHistory.total_commission.total_subscriptions ?? "0"}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                                My Subscriptions
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <CardContent className="bl">
                                            <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                            {commissionHistory.total_commission.total_subscriptions_by_tm ?? "0"}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                                Team Subscriptions
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ maxWidth: "100%" }} >
                            <CardActionArea>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <CardContent>
                                            <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                                ₹{auth.user.wallet.withdraw ?? "0"}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                                Balance Withdrawn
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <CardContent className="bl">
                                            <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                                ₹{Math.round(auth.user.wallet.amount) ?? "0"}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                                 Remaining Balance
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    {/* <Grid item xs={4}>
                        <Card sx={{ maxWidth: 345 }} onClick={()=> alert("Pressed")}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                        25
                                    </Typography>
                                    <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                        Add Today 
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                        
                                    </Typography>
                                    <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                        Add Today 
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Lizard
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Lizards are a widespread group of squamate reptiles, with over 6,000
                                        species, ranging across all continents except Antarctica
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid> */}

                </Grid>
            </Box>
        </div>
    )
}

export default Dashboard;
