import React ,{useEffect}from 'react'
import { Base64 } from 'js-base64';
import { useParams } from 'react-router-dom';
export default function Payment() {
    const {id,amount} = useParams()
    const openPaymentGateway = ()=>{
        // window.location.replace(`http://192.168.29.55:8000/test-payment/${Base64.encode(id)}/${Base64.encode(item.amount)}`)

    }
    useEffect(() => {
        let gateway_window=window.open("http://abhikr.me/Testpayment",'Payment','height=700,width=300');
        // const rc_message = (event)=>{
        //     console.log("MEssage received from Gateway",event.data);
            

        // }
        // gateway_window.addEventListener('message',rc_message)
        // return ()=>{
        //     gateway_window.removeEventListener('message',rc_message)
        // }
        window.onmessage = (e)=>{
            console.log("message",e.data);
        }
    }, []);
    return (
    <div style={{
        display : 'flex',
        flexDirection : 'column',
        padding : 10,
        flex  :1,
        zIndex : 10
    }}>
         {/* <iframe
         style={{
             position : 'fixed',
             width  :"100%",
             height  :"100%",
             top : 0,
             zIndex : 10000,
         }}
        src={`http://abhikr.me/Testpayment`}> */}
         {/* <iframe src={`http://192.168.29.55:8000/test-payment/${Base64.encode(id)}/${Base64.encode(amount)}`}> */}

         {/* </iframe> */}
    </div>
  )
}
