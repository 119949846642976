import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import AnimatedLottieView from 'lottie-react'
import subSuccess from '../../assets/animation/sub-success.json'
import payLoader from "../../assets/animation/payment-loader.json"
import { useParams } from 'react-router-dom';
import config from '../../core/config';
import { Button } from '@mui/material';
import axios from 'axios';
import { setWallet } from '../../store/actions/WalletActions';
export default function PayStatus(props) {
    const dispatch = useDispatch();
    const [completed, setCompleted] = useState(false);
    const params = useParams();
    const [msg,setMsg] = useState("Payment Successful");



    const subscribeFromWallet = async()=>{
        await axios.post(config.rootUrlV1 + "/packageSubscribe" , {
            month_id : params.month_id,
            sub_id : params.sub_id,
        }).then(({data})=>{
            console.log(data);
            if(data.status){
                // dispatch({
                //     type : SET_WALLET,
                //     payload: data.data.user.wallet.amount
                // })
            }else{
                
            }
        }).catch((err)=>{
            console.log(err);
        })
        await dispatch(setWallet('api'));
    } 

    useEffect(() => {
        switch (params.type) {
            case "wallet":
                subscribeFromWallet().then(()=>{
                    setCompleted(true);
                })    
                break;
            case "gateway":
                dispatch(setWallet('api')).then(()=>{
                    setCompleted(true)
                }).catch((err)=>{
                    console.log(err);
                    setCompleted(true)
                })
                break;
        
            default:
                break;
        }
    }, [])
  return (
    <div style={{
        display : 'flex',
        flexDirection : 'column',
        flex : 1,

    }}>
    <div style={{
        display : 'flex',
        flexDirection : 'column',
        height: "100%",
        width: "100%",
        position: 'absolute',
        zIndex: 1000,
        // opacity : 0.5,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10
    }} >
        {
            (!completed) ?
                <AnimatedLottieView style={styles.lottie} animationData={payLoader} loop />
                :
                <>
                    <AnimatedLottieView style={styles.lottie} animationData={subSuccess} loop />
                    <h4 style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        marginTop: 50
                    }}>{msg ?? "Success"}</h4>
                    <Button
                        onClick={() => { props.history.push('/member/dashboard') }}
                        style={{
                            // position: 'absolute',
                            // bottom: 0,
                            // width: Dimensions.get('window').width,
                            // height: 60,
                            backgroundColor : 'greenyellow',
                            borderRadius : 50,
                            paddingHorizontal : 20,
                        }} >Go to Dashboard</Button>
                </>
        }

    </div>
</div>
  )
}
const styles = {
    lottie: {
        // height : "100%",
        width: 300,
        height: 300,
        // bottom : 0

    },
}