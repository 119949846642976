import axios from "axios"
import config from './../../core/config';

export const SET_WALLET = 'SET_WALLET'
export const ADD_TO_WALLET = 'ADD_TO_WALLET'
export const DEDUCT_TO_WALLET = 'DEDUCT_TO_WALLET'

export const setWallet = (type = 'user', wallet = 0) => {
    return async (dispatch, getState) => {
        switch (type) {
            case 'user':

                var balance = getState().auth.user.wallet.amount
                dispatch({
                    type: SET_WALLET,
                    payload: balance,
                })
                break;
            case 'api':
                var token = localStorage.getItem('token');
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                await axios.get(config.rootUrlV1 + "/getwallet").then(({ data }) => {
                    console.log(data);
                    if (data.status) {
                        dispatch({
                            type : SET_WALLET,
                            payload: data.data.amount
                        })
                    } else {
                        alert('Internal Error')
                    }
                }).catch((err)=>{
                    console.log(err);
                })
                break;

            default:
                break;
        }

    }
}

export const addToWallet = (amount) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_TO_WALLET,
            payload: amount,
        })
    }
}

export const deductToWallet = (amount) => {
    return async (dispatch, getState) => {
        var wallet = getState().wallet
        if (wallet < parseFloat(amount)) {
            alert("Insufficiant amount")
        } else {
            dispatch({
                type: DEDUCT_TO_WALLET,
                payload: amount,
            })
        }
    }
}