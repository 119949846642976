import Swal from "sweetalert2";  

export function handelInput(e) {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    return {[name]:value};
}

export function erroSwal() {
    Swal.fire({  
        icon: 'error',  
        title: 'Oops...',  
        text: 'Something went wrong!',  
        footer: '<a href>Why do I have this issue?</a>'  
    }); 
}

export function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
    };
    
    const formattedDate = date.toLocaleString("en-US", options);
  
    // Extract the day, month, and year from the formatted date
    const [month, day, year] = formattedDate.split("/");
    
    // Reformat the date to the desired pattern
    const newFormattedDate = `${day}/${month}/${year}`;
    
    return newFormattedDate;
}