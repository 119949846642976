import React, {useState, useEffect }  from 'react'
import axios from 'axios';
import config from './../../core/config';
import {formatDate} from '../../helpers/index';
import { useSelector } from 'react-redux';


import MyLoader from './../../components/MyLoader';
import {
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Typography, 
    Box,
    Grid,
    Paper
} from '@mui/material';

var total = 0;

const CommissionHome = () => {
    const [commissionHistory, setCommissionHistory] = useState({
        total_commission : {},
        commission_history: []
    });

    const [totalAmount, setTotalAmount] = useState(0);
    const user = useSelector((state) => { return state.auth.user });
    const [progress, setProgress] = useState(false);


    const fetchCommissionHistory = async () => {
        setProgress(true);
        // var token = localStorage.getItem('token');
        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.post(config.rootUrlV1 + "/getCommissionHistory", {
            user_id : user.id,
            type: 5
        }).then(({ data }) => {

          if (data.status) {
            // console.log(data.data);
            setCommissionHistory(data.data);
            setProgress(false);
          }
        }).catch(err => {
          console.log(err);
          setProgress(false);
        });
    }
    
    useEffect(() => {
        fetchCommissionHistory();
    }, []);

    if (progress) {
        return <MyLoader visible={progress}  onclose={() => setProgress(!progress) } />
    }

    return (
        <div className='main'>
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        <Typography align="center" color="primary" gutterBottom variant="h6" component="div">
                            Overall Statistics
                        </Typography>
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item xs={4}>
                                <Paper elevation={10} sx={{ padding: 1.5 }} className="bg-blue">
                                    <Typography align="center" gutterBottom variant="h5" color="white" component="div">
                                        {commissionHistory.total_commission.total_student_added ?? "0"}
                                    </Typography>
                                    <Typography align="center" gutterBottom variant="caption" color="white" component="div">
                                        My <br/>Student
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} >
                                <Paper elevation={10} sx={{ padding: 1.5 }} className="bg-red">
                                    <Typography align="center" gutterBottom variant="h5" color="white" component="div">
                                        {commissionHistory.total_commission.total_subscriptions ?? "0"}
                                    </Typography>
                                    <Typography align="center" gutterBottom variant="caption" color="white" component="div">
                                        My <br/>Subscriptions
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} >
                                <Paper elevation={10} sx={{ padding: 1.5 }} className="bg-green">
                                    <Typography align="center" gutterBottom variant="h5" color="white" component="div">
                                        ₹{parseInt(commissionHistory.total_commission.total_commission_earned) + parseInt(commissionHistory.total_commission.total_commission_earned_by_tm) ?? "0"}
                                    </Typography>
                                    <Typography align="center" gutterBottom variant="caption" color="white" component="div">
                                        My <br/>Commission
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={6} >
                                <Paper elevation={10} sx={{ padding: 1.5 }} className="bg-warning">
                                    <Typography align="center" gutterBottom variant="h5" color="white" component="div">
                                    ₹{commissionHistory.total_commission.commission_redeem ?? "0"}
                                    </Typography>
                                    <Typography align="center" gutterBottom variant="caption" color="white" component="div">
                                        Commission<br/>Redeemed
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={6} >
                                <Paper elevation={10} sx={{ padding: 1.5 }} className="bg-viol">
                                    <Typography align="center" gutterBottom variant="h5" color="white" component="div">
                                        ₹{(parseInt(commissionHistory.total_commission.total_commission_earned) + parseInt(commissionHistory.total_commission.total_commission_earned_by_tm)) - parseInt(commissionHistory.total_commission.commission_redeem) ?? "0"}
                                    </Typography>
                                    <Typography align="center" gutterBottom variant="caption" color="white" component="div">
                                        Commission<br/>Remaining
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Box>
            <br />
            <Typography align="center" color="primary" gutterBottom variant="h6" component="div">
                Commission History
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Student Name</TableCell>
                            <TableCell align="right">Student ID</TableCell>
                            <TableCell align="right">Commission</TableCell>
                            <TableCell align="right">Package Price</TableCell>
                            <TableCell align="right">Package Of</TableCell>
                            <TableCell align="right">Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        (Object.keys(commissionHistory).length !== 0) ?
                        commissionHistory.commission_history.map((row, i) => {
                            // setTotalAmount(totalAmount+row.package_subscription.amount)
                            total =+ row.commission;
                            return <TableRow
                                        key={row.i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.student.name}
                                        </TableCell>
                                        <TableCell align="right">{row.student.user_unique_id}</TableCell>
                                        <TableCell align="right">
                                            <Typography align="center" color="green" gutterBottom variant="button" component="div">
                                            + ₹{row.commission}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">₹{row.package_subscription?.amount ?? 0}</TableCell>
                                        <TableCell align="right">{row.package_subscription?.months.name ?? ''}</TableCell>
                                        <TableCell align="right">{ formatDate(row.created_at) }</TableCell>
                                </TableRow>
                        })
                        
                        : 
                        <Typography align="center" color="secondary" gutterBottom variant="h6" component="div">
                            Loading ...
                        </Typography>
                    }
                    </TableBody>
                    {/* <TableHead>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">TOTAL</TableCell>
                            <TableCell align="right">{total}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead> */}
                </Table>
                </TableContainer>
        </div>
    )
}
export default CommissionHome;