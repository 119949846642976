import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, Tabs, Tab, Card, CardActionArea, Typography, CardContent, Divider, Button, TextField, Modal, FormControl } from '@mui/material';
import { useSelector } from 'react-redux';
import TransactionList from './TransactionList';
import config from './../../core/config';
import axios from 'axios';
import Close from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Base64 } from 'js-base64';

import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import MyLoader from '../../components/MyLoader';
import WithdrawList from './WithdrawList';


export default function Wallet() {
    const theme = useTheme();
    const wallet = useSelector(s => s.wallet);
    const [progress, setProgress] = useState(false);

    const [value, setValue] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [totalBalanceRequest, setTotalBalanceRequest] = useState(0);
    const [withdrawalRequests, setWithdrawalRequests] = useState([]);
    const [amount, setAmount] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const [open, setOpen] = React.useState(false);
    const auth = useSelector(s => s.auth)
    var listener;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const fetchTransactions = async () => {
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.get(config.rootUrlV1 + "/getwallet/userWalletTransactions").then(({ data }) => {
            if (data.status) {
                setTransactions(data.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const fetchBalanceRequest = async () => {
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.post(config.rootUrl + "/user/get-credit-request")
            .then(({ data }) => {
                // console.log(data);
                if (data.status) {
                    setTotalBalanceRequest(data.total_amount);
                }
            }).catch(err => {
                console.log(err);
            });
    }

    const fetchWithdrawalRequests = async () => {
        setProgress(true);
        // var token = localStorage.getItem('token');
        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.get(config.rootUrl + "/withdrawal").then(({ data }) => {

          if (data.status) {
            // console.log("WWE DATA", data.data);
            setWithdrawalRequests(data.data);
            setProgress(false);
          }
        }).catch(err => {
          console.log(err);
          setProgress(false);
        });
    }
    
    
    useEffect(() => {
        fetchTransactions();
        fetchBalanceRequest();
        fetchWithdrawalRequests();
    }, [wallet]);
    

    // const handleBalanceRequest = async () => {
    //     var result = window.confirm("You can only request ₹2500.");
    //     if (result) {
    //         var token = localStorage.getItem('token');
    //         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    //         await axios.post(config.rootUrl + "/user/credit-request").then(({ data }) => {
    //             console.log(data);
    //             if (data.status) {
    //                 // alert(data.msg);
    //             }

    //         }).catch(err => {
    //             console.log(err);
    //         });
    //     }
    // }

    const addBalance = (e) => {
        if (amount < 100) {
            alert("Please enter amount greter then Rs.100");
        } else {

            let popup = window.open(`https://portal.vidyarthiindia.in/payment/${Base64.encode(auth.user.id)}/${Base64.encode(amount)}`, 'PaymentWindow', 'height=500,width=380,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
            listener = window.addEventListener("message", function (event) {
                if (event.origin == config.payment_host) {
                    console.log("received: ", event.data.msg);
                    if (event.data.status) {
                        // alert('Payment' , event.data.msg)
                    } else {
                        // alert('Payment' , event.data.msg)
                    }
                    popup.close()
                }
                console.log(config.payment_host, event.origin, event.data, event.data.status);
                window.removeEventListener('message', listener)
            });

            // setOpen(true);
            // if (parseFloat(amount) >= 100) {
            //     // setAmount(null)

            // } else {
            //     alert("Please add min 100 rs.")
            // }
        }
    }

    const withdrawBalance = async () => {
        if (withdrawAmount < 100) {
            alert("Please enter minimum amount  Rs.100");
        } else if(wallet < 100) {
            alert("Insufficient Balance!");
        } else {
            await axios.post(`${config.rootUrl}/withdrawal/store`, {
                amount: withdrawAmount
            }).then(({ data }) => {
                if (data.status) {
                //   console.log("withdraw", data.data);
                    //   setWithdrawalRequests(data.data);
                    fetchWithdrawalRequests();  
                    setProgress(false);
                    alert(data.message);
                } else {
                    alert(data.message);
                }
            }).catch(err => {
                console.log(err);
                setProgress(false);
            });
        }
    }

    useEffect(() => {
        // fetchWithdrawalRequests();
        return () => {
            window.removeEventListener('message', listener)
        }
    }, [])


    if (progress) {
        return <MyLoader visible={progress}  onclose={() => setProgress(!progress) } />
    }
    return (
        <div style={{ padding: 0, marginTop: 60 }}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Tabs value={value} onChange={handleChange}
                        centered
                        // variant="scrollable"
                        // scrollButtons
                        // allowScrollButtonsMobile
                        // aria-label="scrollable auto tabs example"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Wallet" {...a11yProps(0)} />
                        <Tab label="Withdraw" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <Box sx={{ flexGrow: 1, padding:"10px" }}>
                            <Grid container spacing={1.5}>

                                <Grid item xs={12}>
                                    <Card sx={{ maxWidth: "100%", marginTop: "10px", marginBottom: "10px" }}>
                                        <CardContent>
                                            <Typography align="center" gutterBottom variant="h3" color={(wallet > 500) ? `green` : `error`} component="div">
                                                ₹{wallet ?? 0.00}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color="primary" component="div">
                                                Wallet Balance
                                            </Typography>
                                            <center>

                                                {/* <div style={{
                                                    backgroundColor: config.colors.secondary.light,
                                                    borderRadius: "8px",
                                                    padding: "10px 5px",
                                                    margin: "10px 5px"
                                                }}>
                                                    <Typography align="center" sx={{ fontSize: "15px" }} gutterBottom variant="h6" component="div">
                                                        Total Balance Request
                                                    </Typography>
                                                    <Typography align="center" gutterBottom variant="h6" component="div">
                                                        ₹{totalBalanceRequest ?? 0}
                                                    </Typography>
                                                    <Button variant='contained' size='small' onClick={handleBalanceRequest}>Request Balance</Button>
                                                </div> */}
                                                <TextField style={{ width: "100%", margin: "10px 0" }} value={amount} onChange={(e) => setAmount(e.target.value)} id="outlined-basic" label="Amount" variant="outlined" />
                                                <div className="form-box">
                                                    <FormControl fullWidth>
                                                        <Button size='large' startIcon={<AddIcon />} variant='contained' onClick={addBalance}>Add to Wallet</Button>
                                                        <Button sx={{marginTop: "12px"}} size='large' startIcon={<AccountBalanceIcon />} variant='outlined' onClick={() => handleChangeIndex(1) }>Withdraw Balance</Button>
                                                    </FormControl>
                                                </div>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align="center" gutterBottom variant="h6" color="primary" component="div">
                                        Transaction History
                                    </Typography>
                                    {
                                        (Object.keys(transactions).length !== 0) ?
                                            transactions.map((item, i) => {
                                                return <TransactionList
                                                    key={i}
                                                    status={item.log_type.toLowerCase() == 'credited'}
                                                    amount={item.amount}
                                                    desc={item.log_description}
                                                    date={item.created_at}
                                                />
                                            })
                                            : <></>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Modal
                            disableEscapeKeyDown={false}
                            open={open}
                            // onClose={()=>setOpen(false)}
                            aria-labelledby="payment-gateway"
                            aria-describedby="modal-modal-description"
                        >
                            <Box style={{ position: "fixed", height: "80%", width: "80%", margin: 'auto', left: 0, right: 0, top: 0, bottom: 0 }} >
                                <div className='d-flex justify-content-end'>
                                    <Close sx={{ color: "white", border: '1px solid white', borderRadius: 50 }} onClick={() => setOpen(false)} />
                                </div>
                                <iframe style={{ height: "100%", width: "100%" }} src={`https://portal.vidyarthiindia.in/payment/${Base64.encode(auth.user.id)}/${Base64.encode(amount)}`} ></iframe>
                            </Box>
                        </Modal>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <Box sx={{ flexGrow: 1, padding: "10px" }}>
                            <Grid container spacing={1.5}>
                                <Grid item xs={12} sx={{marginBottom: "-5px"}}>
                                    <Card sx={{ maxWidth: "100%" }} >
                                        <CardActionArea>
                                            <Grid container spacing={1.5}>
                                                <Grid item xs={6}>
                                                    <CardContent>
                                                        <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                                            ₹{auth.user.wallet.withdraw ?? "0"}
                                                        </Typography>
                                                        <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                                            Balance Withdrawn
                                                        </Typography>
                                                    </CardContent>
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <CardContent className="bl">
                                                        <Typography align="center" gutterBottom variant="h4" color={config.colors.primary.main} component="div">
                                                            ₹{Math.round(auth.user.wallet.amount) ?? "0"}
                                                        </Typography>
                                                        <Typography align="center" gutterBottom variant="subtitle1" color={config.colors.primary.main} component="div">
                                                            Remaining Balance
                                                        </Typography>
                                                    </CardContent>
                                                </Grid>
                                            </Grid>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card sx={{ maxWidth: "100%", marginTop: "10px", marginBottom: "10px" }}>
                                        <CardContent>
                                            {/* <Typography align="center" gutterBottom variant="h3" color={(wallet > 500) ? `green` : `error`} component="div">
                                                ₹{wallet ?? 0.00}
                                            </Typography>
                                            <Typography align="center" gutterBottom variant="subtitle1" color="primary" component="div">
                                                Wallet Balance
                                            </Typography> */}
                                            <Typography gutterBottom variant="h6" component="div">
                                                Withdraw Balance
                                            </Typography>
                                            <center>
                                                <TextField style={{ width: "100%", margin: "10px 0" }} value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} id="outlined-basic" label="Enter Withdrawal Amount" variant="outlined" />
                                                <div className="form-box">
                                                    <FormControl fullWidth>
                                                        <Button size='large' startIcon={<AccountBalanceIcon />} color='success' variant='contained' onClick={withdrawBalance}>Request to Withdraw</Button>
                                                    </FormControl>
                                                </div>
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align="center" gutterBottom variant="h6" color="primary" component="div">
                                        Withdraw History
                                    </Typography>
                                    {
                                        (Object.keys(withdrawalRequests).length !== 0) ?
                                            withdrawalRequests.map((item, i) => {
                                                return <WithdrawList
                                                    key={i}
                                                    status={item.status}
                                                    amount={item.amount}
                                                    desc={item.remarks}
                                                    date={item.created_at}
                                                />
                                            })
                                            : <></>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Modal
                            disableEscapeKeyDown={false}
                            open={open}
                            // onClose={()=>setOpen(false)}
                            aria-labelledby="payment-gateway"
                            aria-describedby="modal-modal-description"
                        >
                            <Box style={{ position: "fixed", height: "80%", width: "80%", margin: 'auto', left: 0, right: 0, top: 0, bottom: 0 }} >
                                <div className='d-flex justify-content-end'>
                                    <Close sx={{ color: "white", border: '1px solid white', borderRadius: 50 }} onClick={() => setOpen(false)} />
                                </div>
                                <iframe style={{ height: "100%", width: "100%" }} src={`https://portal.vidyarthiindia.in/payment/${Base64.encode(auth.user.id)}/${Base64.encode(amount)}`} ></iframe>
                            </Box>
                        </Modal>
                    </TabPanel>
                </SwipeableViews>
            </Box>
            <br />
            <br />
        </div>
    )
}


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}
