import React, { Component } from 'react'
// import { erroSwal } from '../helpers/index';
import axios from 'axios';
import config from '../core/config';
import { connect } from 'react-redux';
import { set_login } from '../store/actions/AuthActions';
// import LogoDark from '../assets/app-logo.png';
import {
    Box, 
    Button, 
    Grid, 
    Typography, 
    FormControl, 
    InputLabel, 
    OutlinedInput
} from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

class Login extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            email: '',
            password: '',
            showPassword: false,
            errors: {},
            errorsBag: []
        };
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    }

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    handleForm = (key, event) => {
        this.setState({
            [key]: event.target.value
        })
    }


    formValidate = (values) => {
        const errors = {};
        errors.email = !values.email ? "Email or Phone is required" : "";
        errors.password = !values.password ? "Password is required" : "";
        return errors;
    }

    handelLoginform = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: !this.state.isLoading,
        });
        const data = {
            email: this.state.email,
            password: this.state.password
        }

        this.setState({
            errors : this.formValidate(data),
        });
        

        axios.post(`${config.rootUrl}/login`, data)
            .then(res => {
                if (res.data.status) {

                    localStorage.setItem('token', res.data.token);
                    this.props.set_login(res.data.data.user);
                        this.setState({
                            isLoading: !this.state.isLoading,
                        });
                        // this.props.history.push('/member/dashboard');
                    // console.log(res.data);

                } else {
                    // erroSwal();
                    alert(res.data.message);
                    this.setState({
                        errorsBag: res.data.errors,
                        isLoading: !this.state.isLoading,
                    });
                }
            })
            .catch(err => {
                // this.setState({
                //     errorsBag: err.response.data,
                //     isLoading: !this.state.isLoading,
                // });
                console.log(err);
            });
    }


    render() {
        return (
            // <div>
            //     <form onSubmit={this.handelLoginform}>
            //         <div className="login">
            //             <h1>Login</h1>
            //             <form method="post">
            //             <input type="text" name="u" placeholder="Email or Phone" required="required" onChange={(e) => { this.handleForm("email", e) }} />
            //             <input type="password" name="p" placeholder="Password" required="required" onChange={(e) => { this.handleForm("password", e) }} />
            //             <button type="submit" className="btn btn-primary btn-block btn-large"
            //                 disabled={this.state.isLoading ? true : false}
            //             >{this.state.isLoading ? 'Please Wait...' : 'Login'}</button>
            //             </form>
            //         </div>
            //     </form>
            // </div>

            // <div>
            //     <form onSubmit={this.handelLoginform }>
            //         <label>
            //             <p>Email Address</p>
            //             <input type="text" onChange={ (e) =>{this.handleForm("email" ,e)} } />
            //         </label>
            //         <label>
            //             <p>Password</p>
            //             <input type="password" onChange={ (e) =>{this.handleForm("password" ,e)} } />
            //         </label>
            //         <div>
            //             <button type="submit">Submit</button>
            //         </div>
            //     </form>
            // </div>
            
            
            <div className='LoginBox'>
                <div className='LoginImg'>
                    <img src="https://portal.vidyarthiindia.in/dkd-login.png" width="100%" />
                </div>
                <Typography align="left" color="primary" gutterBottom variant="h4" component="div">
                    LOGIN
                </Typography>
                <div className='LoginForm'>
                    <Box sx={{flexGrow: 1, marginY: 2}}>
                        <Grid container spacing={1.5}>
                            <Grid item xs={12}>
                                <form onSubmit={this.handelLoginform}>
                                    <div className="form-box">
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="outlined-phone">Phone or Mobile</InputLabel>
                                            <OutlinedInput id="outlined-phone"
                                                name="email"
                                                label="Phone or Mobile" variant="outlined" 
                                                onChange={(e) => { this.handleForm("email", e) }}
                                                // onChange={(event) => {setState({...this.state.formData, uid: event.target.value})}}
                                                // error={this.state.email !== ""  && true}
                                                // helperText={this.state.formErrors.uid}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="form-box">
                                        <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-password">Password</InputLabel>
                                            <OutlinedInput id="outlined-password"
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                name="password"
                                                label="Password" variant="outlined"
                                                onChange={(e) => { this.handleForm("password", e) }}
                                                // onChange={(event) => {setState({...this.state.formData, name: event.target.value})}}
                                                // error={this.state.formErrors.name && true}
                                                // helperText={this.state.formErrors.name}

                                                endAdornment={
                                                    <InputAdornment position="end">
                                                      <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                      >
                                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                      </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="form-box">
                                        <FormControl fullWidth>
                                            <Button type="submit" color="primary" variant="contained" size="large">
                                                {this.state.isLoading ? 'Please Wait...' : 'LOGIN'}
                                            </Button>
                                        </FormControl>
                                    </div>
                                </form>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                {/* <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-7">
                                <img src="assets/vendors/images/login-page-img.png" alt="img" />
                            </div>
                            <div className="col-md-6 col-lg-5">
                                <div className="login-box bg-white box-shadow border-radius-10">
                                    <div className="login-title">
                                        <h2 className="text-center text-primary">Login To DKD</h2>
                                    </div>
                                    <form onSubmit={this.handelLoginform}>
                                        <div className="input-group custom">
                                            <input type="text" onChange={(e) => { this.handleForm("email", e) }} className="form-control form-control-lg" placeholder="Email Address" />
                                            <div className="input-group-append custom">
                                                <span className="input-group-text"><i className="icon-copy dw dw-user1" /></span>
                                            </div>
                                        </div>
                                        {
                                            (Object.keys(this.state.errorsBag).length > 0) ?
                                                (<p className="text-error ">{this.state.errorsBag[0].email}</p>) : ''
                                        }
                                        <div className="input-group custom">
                                            <input type="password" onChange={(e) => { this.handleForm("password", e) }} className="form-control form-control-lg" placeholder="**********" />
                                            <div className="input-group-append custom">
                                                <span className="input-group-text"><i className="dw dw-padlock1" /></span>
                                            </div>
                                        </div>
                                        {
                                             (Object.keys(this.state.errorsBag).length > 0) ?
                                                (<p className="text-error ">{this.state.errorsBag[1].password}</p>) : ''
                                        }
                                        <div className="row" style={{marginTop: "20",}}>
                                            <div className="col-sm-12">
                                                <br />
                                                <div className="input-group mb-0">

                                                    use code for form submit
                                                    <input className="btn btn-primary btn-lg btn-block w-100" type="submit" value={this.state.isLoading ? 'Please Wait...' : 'Sign In'} disabled={this.state.isLoading ? true : false} />

                                                    <a className="btn btn-primary btn-lg btn-block" href="index.html">Sign In</a>
                                                </div>
                                                <div className="font-16 weight-600 pt-10 pb-10 text-center" data-color="#707373">OR</div>
                                                <div className="input-group mb-0">
                                                    <a className="btn btn-outline-primary btn-lg btn-block" href="register.html">Register To Create Account</a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        )
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         setLogin: user => dispatch({
//             type: 'SET_LOGIN',
//             payload: user
//         })
//     };
// }

export default connect(null, { set_login })(Login);