import { ADD_TO_WALLET, DEDUCT_TO_WALLET, SET_WALLET } from "../actions/WalletActions";


const WalletReducer = (state = 0.00 , action) => {
 
  switch (action.type) {
    case SET_WALLET:
      return parseFloat(action.payload)
    case ADD_TO_WALLET:
      return state + parseFloat(action.payload)
    case DEDUCT_TO_WALLET:
      return state - parseFloat(action.payload)
    default:
      return state
  }
}

export default WalletReducer;