
import React, {useState, useEffect } from 'react'
import {
    Box, 
    Button, 
    Grid, 
    Typography, 
    TextField, 
    FormControl, 
    InputLabel, 
    MenuItem, 
    FormHelperText,
} from '@mui/material';
import Select from '@mui/material/Select';
// import FileUploadIcon from '@mui/icons-material/FileUpload';
import HomeIcon from '@mui/icons-material/Home';
import MyBreadcrumb from './../../components/MyBreadcrumb';
import axios from 'axios';
import config from '../../core/config';
import MySnackbar from '../../components/MySnakebar';
import { useHistory  } from 'react-router-dom';
import { useSelector } from 'react-redux';



const CreateDownlevel = () => {
    let history = useHistory();
    const [level, setLevel] = useState("");
    const user = useSelector((state) => { return state.auth.user })
    const [levelList, setLevelList] = useState([]);
    const [ showSnakebar, setShowSnakebar ] = useState(false);
    const [ snakebardata, setSnakebardata ] = useState({
        label : '',
        color: "inherit",
        type: "success"
    });
    const [ formErrors, setFormErrors ] = useState({});
    const [ isSubmit, setIsSubmit ] = useState(false);
    const [formData, setFormData] = useState({
        uid : '',
        name : '',
        email : '',
        phone : '',
        level : '',
        // account_holder : '',
        // bank_name : '',
        // account_number : '',
        // ifsc_code : '',
        // nominee : '',
        // nominee_relation : '',
        // addhar_no : '',
        // id_proof_file : '',
        // pan_no : '',
    });

    const breadcrumbs = [
        {label: "Dashboard", uri: "dashboard", icon: <HomeIcon fontSize="small" /> },
        {label: "Cordinate", uri: "downlevel", icon: null},
        {label: "Add New", uri: null, icon: null}
    ];


    const fetchLevelsList = async () => {
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await axios.get(config.rootUrl + "/getDkdLevel").then(({ data }) => {
          if (data.status) {
            setLevelList(data.data);
          }
        }).catch(err => {
          console.log(err);
        });
    }

    useEffect(() => {
        if(user.dkd_commission_struct_id == 2 || user.dkd_commission_struct_id == 3){
            history.push('/member/downlevel');
        }
        fetchLevelsList();
    }, []);


    const formValidate = (values) => {
        const errors = {};
        errors.uid = !values.uid ? "Reference Code is required" : "";
        errors.name = !values.name ? "Cordinate Name is required" : "";
        errors.phone = !values.phone ? "Phone is required" : "";
        // errors.email = !values.email ? "Email Address is required" : "";
        errors.level = !values.level ? "Level is required" : "";
        // errors.account_holder = !values.account_holder ? "Account Holder is required" : "";
        // errors.bank_name = !values.bank_name ? "Bank Name is required" : "";
        // errors.account_number = !values.account_number ? "Account No. is required" : "";
        // errors.ifsc_code = !values.ifsc_code ? "IFSC Code is required" : "";
        // errors.nominee = !values.nominee ? "Nominee is required" : "";
        // errors.nominee_relation = !values.nominee_relation ? "Relation with nominee is required" : "";
        // errors.addhar_no = !values.addhar_no ? "Aadhar No. is required" : "";
        // errors.id_proof_file = !values.id_proof_file ? "ID Proof File is required" : "";
        return errors;
    }

    const handelSubmit = async (e) => {
        e.preventDefault();
        console.log("Data", formData);
        
        setFormErrors(formValidate(formData));
        console.log("Errors", formErrors);
        
        // if(Object.keys(formErrors).length === 0) {
            var token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            // {headers: {'Content-Type': 'multipart/form-data'}}
            await axios.post(config.rootUrl + "/user/create", formData).then(({ data }) => {
            //   console.log(data);
                if (data.status) {
                    
                    setShowSnakebar(true);
                    setSnakebardata({
                        label: data.message,
                        color: "success",
                        type: "success"
                    })
                    // setLevelList(data.data);
                    setTimeout(()=>{
                        history.push('downlevel');
                    }, 2000)
                } else {
                    let htmlText;
                    if('errors' in data) {
                        htmlText = data.errors.map((error, e) => {
                        return <Typography variant="subtitle2">{error}</Typography>
                        });
                    } else {
                        htmlText = data.message;
                    }
                    setShowSnakebar(true);
                    setSnakebardata({
                        label: htmlText,
                        color: "error",
                        type: "error"
                    })
                }
            }).catch(err => {
              console.log(err);
            });
        // }
        setIsSubmit(true);
        
    }


    return (
        <div style={{position: "relative", background: "#fff", height: "100%"}}>
            <div className="main">
                <MyBreadcrumb data={breadcrumbs} />
                <br />
                <Typography align="left" color="secondary" gutterBottom variant="h6" component="div">
                    Add Cordinater
                </Typography>
                <Box sx={{flexGrow: 1, marginY: 2}}>
                    <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                            <form onSubmit={handelSubmit}>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="uid"
                                            label="Reference Code" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, uid: event.target.value})}}
                                            error={formErrors.uid && true}
                                            helperText={formErrors.uid}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="name"
                                            label="Cordinater Name" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, name: event.target.value})}}
                                            error={formErrors.name && true}
                                            helperText={formErrors.name}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Email Address (optional)" variant="outlined"
                                            name="email"
                                            onChange={(event) => {setFormData({...formData, email: event.target.value})}}
                                            error={formErrors.email && true}
                                            helperText={formErrors.email}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Phone or Mobile" variant="outlined"
                                            name="phone"
                                            inputProps={{ maxLength: 10 }}
                                            onChange={(event) => {setFormData({...formData, phone: event.target.value})}}
                                            error={formErrors.phone && true}
                                            helperText={formErrors.phone}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth error={(formErrors.level && true)}>
                                        <InputLabel id="demo-simple-select-autowidth-label">Level</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={formData.level}
                                            label="Level"
                                            name="level"
                                            onChange={(event) => {setFormData({...formData, level: event.target.value})}}
                                        >
                                            {
                                                levelList.map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.level }</MenuItem>
                                                })
                                            }
                                        </Select>
                                        <FormHelperText error={(formErrors.level && true)}>{formErrors.level}</FormHelperText>
                                    </FormControl>
                                </div>
                                {/* <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="account_holder"
                                            label="Account Holder" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, account_holder: event.target.value})}}
                                            error={formErrors.account_holder && true}
                                            helperText={formErrors.account_holder}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="bank_name"
                                            label="Bank Name" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, bank_name: event.target.value})}}
                                            error={formErrors.bank_name && true}
                                            helperText={formErrors.bank_name}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="account_number"
                                            label="Account Number" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, account_number: event.target.value})}}
                                            error={formErrors.account_number && true}
                                            helperText={formErrors.account_number}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="ifsc_code"
                                            label="IFSC Code" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, ifsc_code: event.target.value})}}
                                            error={formErrors.ifsc_code && true}
                                            helperText={formErrors.ifsc_code}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="nominee"
                                            label="Nominee Name" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, nominee: event.target.value})}}
                                            error={formErrors.nominee && true}
                                            helperText={formErrors.nominee}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="nominee_relation"
                                            label="Relation with Nominee" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, nominee_relation: event.target.value})}}
                                            error={formErrors.nominee_relation && true}
                                            helperText={formErrors.nominee_relation}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="addhar_no"
                                            inputProps={{ maxLength: 12 }}
                                            label="Aadhar No" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, addhar_no: event.target.value})}}
                                            error={formErrors.addhar_no && true}
                                            helperText={formErrors.addhar_no}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic"
                                            name="pan_no"
                                            inputProps={{ maxLength: 10 }}
                                            label="Pancard No" variant="outlined" 
                                            onChange={(event) => {setFormData({...formData, pan_no: event.target.value})}}
                                        />
                                    </FormControl>
                                </div> */}
                                {/* <div className="form-box">
                                    <FormControl fullWidth>
                                        <Button variant="contained" color={(formErrors.id_proof_file) ? `error` : `primary`} 
                                            component="label"
                                            endIcon={<FileUploadIcon />}
                                        >
                                            Upload ID Proof File
                                            <input hidden name="id_proof_file" accept="image/*" type="file" 
                                                onChange={(event) => {setFormData({...formData, id_proof_file: event.target.files[0]})}}
                                            />
                                        </Button>
                                        <FormHelperText error={(formErrors.id_proof_file && true)}>{formErrors.id_proof_file}</FormHelperText>
                                    </FormControl>
                                </div> */}
                                <div className="form-box">
                                    <FormControl fullWidth>
                                        <Button type="submit" color="primary" variant="contained" size="large">Add</Button>
                                    </FormControl>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            {
                showSnakebar ? <MySnackbar data={snakebardata} visible={showSnakebar} handleClose={()=> {setShowSnakebar(false)}} /> : ""
            }
        </div>
    )
}

export default CreateDownlevel;